import { ContentfulClientProvider } from "@expert/contentful";
import { RouterProvider } from "react-router-dom";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { FeedbackContextProvider } from "@soluto-private/expert-workspace-feedback";
import { GlobalStyles, GlobalThemeProvider } from "@expert/theme";
import { createClient } from "contentful";
import { RoutePath, router } from "./routes";
import { AuthenticationProvider } from "./auth";
import { ReactQueryClient } from "./providers";
import { env } from "./utils/env";

const contentfulClient = createClient(env.contentful);

export function App() {
    return (
        <GlobalThemeProvider>
            <GlobalStyles />
            <AuthenticationProvider redirectPath={RoutePath.Home}>
                <ContentfulClientProvider client={contentfulClient}>
                    <ReactQueryClient>
                        <Notifications
                            limit={1}
                            position="top-center"
                            zIndex={1000}
                            data-testid="notifications-center"
                        />
                        <FeedbackContextProvider expertFeedbackUrl={env.expertFeedbackUrl}>
                            <RouterProvider router={router} />
                        </FeedbackContextProvider>
                    </ReactQueryClient>
                </ContentfulClientProvider>
            </AuthenticationProvider>
        </GlobalThemeProvider>
    );
}
