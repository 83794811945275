export const injectExternalScript = (id: string, src: string, crossOrigin = "") => {
    const existingScript = document.getElementById(id);
    if (existingScript) return; // already injected

    const script = document.createElement("script");
    script.id = id;
    script.src = src;
    script.type = "text/javascript";
    script.crossOrigin = crossOrigin;
    document.head.appendChild(script);
};

export const injectInternalScript = (id: string, content: string) => {
    const existingScript = document.getElementById(id);
    if (existingScript) return; // already injected

    const script = document.createElement("script");
    script.id = id;
    script.type = "text/javascript";
    script.innerHTML = content;
    document.head.appendChild(script);
};
