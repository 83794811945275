import { useExpertNoteStore } from "@soluto-private/expert-workspace-notepad";
import { removePii } from "@soluto-private/expert-workspace-pii";
import {
    addMessage,
    formatTimestamp,
    getDefaultMessage,
    solveSessionClosed,
    solveSessionStarted,
} from "@soluto-private/expert-workspace-timeline";
import { sendBusinessEvent } from "../analytics";
import { subscribeToExpert, subscribeToMessage } from "../graphql";
import { getSessionDetails, getToken, useExpertAssistStore } from "../state";
import { fetchExistingMessages, resetSession } from "../bot-messages";

export const initEaStoreSubscriptions = () => {
    useExpertAssistStore.subscribe(
        (state) => [state.sessionId, state.callSid, state.partner],
        ([sessionId, callSid, partner], [prevSessionId]) => {
            //Previous session is closed
            if (prevSessionId && prevSessionId !== sessionId && prevSessionId !== "" && partner) {
                const identities = { SessionId: prevSessionId };
                solveSessionClosed({ sessionId: prevSessionId, partner });

                const { note } = useExpertNoteStore.getState();
                if (note)
                    void removePii(note).then((piiScrubbedNote) => {
                        sendBusinessEvent({
                            eventName: "SaveExpertNote",
                            identities,
                            meta: { SkipMixpanel: true },
                            attributes: { note: piiScrubbedNote },
                        });
                    });
            }
            if (sessionId && prevSessionId !== sessionId && partner) {
                solveSessionStarted({ sessionId, callSid, withCustomer: !!callSid, partner });
            }
        },
        {
            fireImmediately: true,
        },
    );

    useExpertAssistStore.subscribe(
        (state) => [state.expertProfile.id, state.token, state.expertProfile.lastName],
        ([expertId, token, lastName]) => {
            //Expert has logged in - subscribe to mutation
            if ((expertId ?? lastName) && token) {
                void subscribeToExpert(expertId ?? "");
            }
        },
        {
            fireImmediately: true,
        },
    );

    useExpertAssistStore.subscribe(
        (state) => state.callSid,
        (callSid) => {
            resetSession();
            if (callSid) {
                // grab messages for the active call if they exist
                void fetchExistingMessages({ ...getSessionDetails(), token: getToken(), callSid });
            }
        },
    );

    useExpertAssistStore.subscribe(
        (state) => [state.callSid, state.sessionId],
        ([callSid, sessionId], [prevCallSid, previousSessionId]) => {
            const isNewCallSid = prevCallSid !== callSid;
            const isNewSessionId = previousSessionId !== sessionId;

            if (!callSid && prevCallSid && sessionId) {
                addMessage({
                    ...getDefaultMessage("offCall"),
                    id: `offCall_${crypto.randomUUID()}`,
                    timestamp: formatTimestamp(),
                    isUnread: true,
                });
            }
            if (callSid && sessionId && (isNewCallSid || isNewSessionId)) subscribeToMessage(sessionId, callSid);
        },
    );
};
