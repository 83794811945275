export function DisabledSendIcon() {
    return (
        <svg fill="none" height="44" viewBox="0 0 44 44" width="44" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1505_155655)">
                <rect fill="var(--mantine-color-dark-4)" height="44" rx="22" width="44" />
                <path
                    clipRule="evenodd"
                    d="M31.4199 22.8944L14.1648 30.8577C13.407 31.2075 12.5027 30.8902 12.1451 30.1491C12.0496 29.9511 12 29.7348 12 29.5158V25.3886C12 24.3911 12.7594 23.5496 13.7715 23.4256L21.4012 22.4906C21.6784 22.4566 21.8749 22.2094 21.8402 21.9383C21.8114 21.7139 21.6307 21.5372 21.4012 21.5091L13.7715 20.5741C12.7594 20.4501 12 19.6085 12 18.611V14.4838C12 13.6643 12.6793 13 13.5173 13C13.7412 13 13.9623 13.0485 14.1648 13.1419L31.4199 21.1052C31.9251 21.3384 32.1414 21.9279 31.903 22.422C31.8029 22.6295 31.6321 22.7965 31.4199 22.8944Z"
                    fill="var(--mantine-color-dark-3)"
                    fillRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="clip0_1505_155655">
                    <rect fill="white" height="44" rx="22" width="44" />
                </clipPath>
            </defs>
        </svg>
    );
}
