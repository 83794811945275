import { Container } from "@mantine/core";
import { useExpertAssistStore } from "../../state";
import { lobs, partners } from "./configValues";
import { RadioGroup } from "./RadioGroup";

export function DevToolsMenuItem() {
    const { callSid, lob, partner, setLob, setPartner } = useExpertAssistStore();

    const isDisabled = !!callSid;

    return (
        <Container>
            <RadioGroup
                label="Line of business"
                options={lobs}
                isDisabled={isDisabled}
                currentSelectedOption={lob}
                onChange={setLob}
            />
            <RadioGroup
                label="Partner"
                options={partners}
                isDisabled={isDisabled}
                currentSelectedOption={partner}
                onChange={setPartner}
            />
        </Container>
    );
}
