export type EventType = "expertAssist.loginSuccess" | "expertAssist.reset";

export interface Expert {
    __typename: "Expert";
    callSid?: string;
    partner?: string;
    employeeId: string;
    eventType?: EventType;
    lastUpdated?: string;
    sessionId: string;
    isProactiveEnabled?: boolean;
}

export interface UpdateExpertMutation {
    updateExpert?: Expert | null;
}

export const updateExpert = /* GraphQL */ `
    mutation UpdateExpert($input: UpdateExpertInput!) {
        updateExpert(input: $input) {
            callSid
            partner
            employeeId
            eventType
            lastUpdated
            sessionId
            isProactiveEnabled
            __typename
        }
    }
`;
