import { type GraphQLSubscription } from "@aws-amplify/api";
import { getEaLogger, isAlreadySubscribed, setSessionDetails, setSubscription } from "../../state";
import { getClient } from "../client";
import { onUpdateExpert, type OnUpdateExpertSubscription } from "./subscriptions";
import { handleExpertUpdate } from "./updateExpert";

export async function subscribeToExpert(employeeId: string) {
    const localLogger = getEaLogger().child({ module: "subscribeToExpert" });
    try {
        if (isAlreadySubscribed("onUpdateExpert")) {
            return;
        }

        if (employeeId) {
            const subscription = getClient()
                .graphql<GraphQLSubscription<OnUpdateExpertSubscription>>({
                    query: onUpdateExpert,
                    variables: { employeeId },
                })
                .subscribe({
                    next: (value: { data: OnUpdateExpertSubscription }) => {
                        const expert = value.data.onUpdateExpert;
                        if (expert?.sessionId) {
                            const { lastUpdated, callSid, sessionId, ...mutationResult } = expert;
                            localLogger.info(
                                { mutationResult, lastUpdated, callSid, sessionId },
                                "Expert updated via mutation",
                            );
                            setSessionDetails(expert.sessionId, expert.callSid, expert.partner);
                        }
                        return expert;
                    },
                    error: (err: unknown) => {
                        localLogger.error({ err }, "An error occurred during onUpdateExpert mutation subscription");
                    },
                });

            setSubscription("onUpdateExpert", subscription);
            await handleExpertUpdate(employeeId, "expertAssist.loginSuccess");
        } else {
            // Generate a random sessionId as fallback if we don't have an employeeId so the expert
            // can at least use the reactive bot
            setSessionDetails(crypto.randomUUID());
        }
    } catch (err: unknown) {
        localLogger.error({ err }, "An error occurred during onUpdateExpert subscription");
    }
}
