import { Modal } from "@mantine/core";
import type { Logger } from "@expert/logging";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { useEffect, useRef, useState } from "react";
import { getFullStoryUrl, trackFullStoryEvent } from "@expert/monitoring";
import { useFeedbackContext } from "../../context";
import { sendFeedback } from "../../sendFeedback";
import { useFeedbackDraftStore } from "../../store";
import classes from "../../styles.module.css";
import type { PartialFeedbackPayload } from "../../types";
import { feedbackUIMapping } from "../../types";
import { FeedbackSuccess } from "../success-animation";
import { Feedback } from "./Feedback";

interface FeedbackModalProps {
    isOpen: boolean;
    logger: Logger;
    onSubmit?: VoidFunction;
    onClose: VoidFunction;
    onError: VoidFunction;
    feedbackPayload: PartialFeedbackPayload;
}
const TRANSITION_DURATION = 300; // TODO: Import this from theme constants when ea standalone moves over

export function FeedbackModal({
    isOpen = false,
    logger: loggerProp,
    onClose: onCloseProp,
    onSubmit: onSubmitProp,
    onError,
    feedbackPayload,
}: FeedbackModalProps) {
    const { expertFeedbackUrl } = useFeedbackContext();
    const { resetDraft, draft } = useFeedbackDraftStore();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const title = isSuccess ? feedbackUIMapping.success.title : feedbackUIMapping.default.title;
    const timeoutRef = useRef<number | undefined>(undefined);
    const { dispatcher } = useReactAnalytics();

    const logger = loggerProp.child({ module: "FeedbackModal" });

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            const fsUrl = await getFullStoryUrl();
            await sendFeedback(expertFeedbackUrl, {
                ...feedbackPayload,
                extraData: { ...feedbackPayload.extraData, "Fullstory URL": fsUrl },
                text: draft.text,
                category: draft.issue,
            });
            setIsLoading(false);
            onSubmitProp?.();
            resetDraft();
            logger.info("Feedback submitted");
            trackFullStoryEvent("FeedbackSubmitted", {});
            setIsSuccess(true);
        } catch (error) {
            logger.error(error, "Error sending feedback");
            setIsLoading(false);
            onError();
        }
    };

    const onClose = () => {
        void dispatcher.dispatchAction("Click", "CloseFeedback", {});
        if (draft.text) {
            void dispatcher.dispatchAction("Click", "SaveFeedback", {});
            logger.info("Feedback saved");
        }
        onCloseProp();
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setIsSuccess(false);
        }, TRANSITION_DURATION) as unknown as number;
    };
    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    return (
        <Modal
            zIndex="var(--mantine-priority-highest)"
            opened={isOpen}
            onClose={onClose}
            title={title}
            size="36rem"
            centered
            className={classes.modal}
        >
            {isSuccess ? <FeedbackSuccess /> : <Feedback onSubmit={onSubmit} isLoading={isLoading} logger={logger} />}
        </Modal>
    );
}
