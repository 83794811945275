import type { ImgHTMLAttributes } from "react";

export function preferWebpFormat(src: ImgHTMLAttributes<HTMLImageElement>["src"]) {
    if (!src) return src;

    const url = new URL(src);
    const { searchParams } = url;
    if (searchParams.has("fm")) return src;

    searchParams.set("fm", "webp");
    return url.toString();
}
