import { useCallback, useEffect, useState } from "react";
import { sendBusinessEvent } from "../analytics";

export const useIsTabActive = () => {
    const [isTabVisible, setIsTabVisible] = useState(true);

    const handleVisibilityChange = useCallback(() => {
        const isVisible = document.visibilityState === "visible";
        setIsTabVisible(isVisible);
        sendBusinessEvent({
            eventName: isVisible ? "WindowDidBecomeActive" : "WindowDidBecomeInactive",
        });
    }, []);

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    return isTabVisible;
};
