import type { ExpertAssistMessage } from "../shared-types";

export const fallbackDefaultMessages = [
    {
        key: "firstOpen",
        text: `Hey $EXPERT_NAME! 👋 I have limited info until you're on a call, but can still help with some things.`,
        type: "Default",
        id: `firstOpen_${crypto.randomUUID()}`,
        tag: "New chat",
        isUnread: true,
    },
    {
        key: "inCallDeviceInfo",
        text: "What kind of device do they need help with? Not listed? Type it in!",
        type: "Default",
        id: `deviceInfo_${crypto.randomUUID()}`,
        tag: "Device info",
        isUnread: true,
    },
    {
        key: "manualReset",
        text: "The chat was reset. I’m ready to help!",
        type: "Default",
        id: `manualReset_${crypto.randomUUID()}`,
        isUnread: true,
    },
    {
        key: "offCall",
        text: `I have limited info when you're off calls, but you can still ask me some stuff! How about a joke or 
dinner recipe? 🍝`,
        type: "Default",
        id: `offCall_${crypto.randomUUID()}`,
        tag: "Off-call chat",
        isUnread: true,
    },
] as ExpertAssistMessage[];
export type ResetReason = keyof typeof fallbackDefaultMessages;
