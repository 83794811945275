import ky, { HTTPError, type KyInstance } from "ky";
import { type ReactiveMessagePayload } from "@expert/solve-tools";
import { env } from "../../utils/env";
import type { ExpertMessagePayload, GaiaClient, GetHistoryQuery, HistoricalMessage } from "../types";
import { noSessionHistory, reportErrorResponse } from "./errors";
import { createHeadersHook } from "./headers";
import { type OrchestratorExpertMessagePayload, type SessionHistory, type SessionHistoryQueryParams } from "./types";

export class OrchestratorGaiaClient implements GaiaClient {
    private orchestratorApi: KyInstance;

    constructor(token: string) {
        this.orchestratorApi = ky.create({
            prefixUrl: `${env.gaiaUrl}/orchestrator`,
            retry: 0,
            hooks: {
                beforeRequest: [createHeadersHook(token)],
            },
        });
    }

    public async postExpertMessage({
        expertId,
        sessionId,
        callSid,
        messageId,
        message,
    }: ExpertMessagePayload): Promise<Partial<ReactiveMessagePayload>> {
        return this.sendOrchestratorClientMessage({
            userId: expertId,
            userType: "expert",
            sessionGroupId: sessionId,
            sessionId: callSid ?? sessionId,
            messageId,
            message,
        });
    }

    private async sendOrchestratorClientMessage(
        message: OrchestratorExpertMessagePayload,
    ): Promise<Partial<ReactiveMessagePayload>> {
        try {
            const response = await this.orchestratorApi.post("invoke", { json: message });
            return response.json<Partial<ReactiveMessagePayload>>();
        } catch (e) {
            if (!(e instanceof HTTPError)) throw e;
            await reportErrorResponse(e.response.status, e.response, message);
            return {
                response: undefined,
            };
        }
    }

    public async getMessagesHistory({ sessionId, callSid }: GetHistoryQuery): Promise<HistoricalMessage[]> {
        const params = {
            sessionGroupId: sessionId,
            sessionId: callSid ?? sessionId,
        };

        const result = await this.getSessionHistory(params);

        return (result as SessionHistory).messages;
    }

    private async getSessionHistory({
        limit,
        ...rest
    }: SessionHistoryQueryParams): Promise<SessionHistory | Record<string, unknown>> {
        try {
            const response = await this.orchestratorApi.get("session-history", {
                searchParams: { ...rest, limit: `${limit ?? 100}` },
            });
            return response.json<SessionHistory | Record<string, unknown>>();
        } catch (e) {
            if (!(e instanceof HTTPError)) throw e;
            await reportErrorResponse(e.response.status, e.response, rest);
            return noSessionHistory;
        }
    }
}
