import type { ContentfulClientApi, EntryFieldTypes, EntrySkeletonType } from "contentful";

interface MessageList {
    name: string;
    messages: {
        text?: string;
        type?: string;
        key?: string;
        tag?: string;
    }[];
}

interface MessageFields {
    text: EntryFieldTypes.Text;
    type: EntryFieldTypes.Text;
    key: EntryFieldTypes.Text;
    tag: EntryFieldTypes.Text;
}

interface MessageListFields {
    name: EntryFieldTypes.Text;
    messages: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<EntrySkeletonType<MessageFields, "messages">>>;
}

export const getDefaultMessages = async (
    contentfulClient: ContentfulClientApi<undefined>,
    listName = "default",
): Promise<MessageList> => {
    const entries = await contentfulClient.withoutUnresolvableLinks.getEntries<EntrySkeletonType<MessageListFields>>({
        content_type: "expertAssistDefaultMessageList",
        "fields.name": listName,
    });
    const { name, messages } = entries.items[0].fields;
    return {
        name,
        messages: messages.map((message) => {
            return {
                text: message?.fields.text,
                type: message?.fields.type,
                key: message?.fields.key,
                tag: message?.fields.tag,
            };
        }),
    };
};
