import { useEffect } from "react";
import { Portal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import type { Logger } from "@expert/logging";
import type { PartialFeedbackPayload } from "../../types";
import { FeedbackButton } from "../feedback-button";
import { FeedbackModal } from "../feedback-modal";

interface FeedbackPortalProps {
    logger: Logger;
    payload: PartialFeedbackPayload;
    source: string;
    showNotification: (type: "success" | "error") => void;
    taskStatus?: string;
}

export function FeedbackPortal({ payload, showNotification, taskStatus, source, logger }: FeedbackPortalProps) {
    const [opened, { open, close }] = useDisclosure(false, {
        onClose: () => {
            showNotification("success");
        },
    });
    const error = () => {
        showNotification("error");
    };

    useEffect(() => {
        // to handle the case of closing the modal on an incoming call or when the task
        // changes to a wrapping state (meaning the session summary modal would be opening)
        if (taskStatus === "pending" || taskStatus === "wrapping") {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskStatus]);

    return (
        <Portal>
            <FeedbackButton openModal={open} source={source} logger={logger} />
            <FeedbackModal isOpen={opened} onClose={close} onError={error} feedbackPayload={payload} logger={logger} />
        </Portal>
    );
}
