import { Button } from "@mantine/core";
import { useExpertAssistStore } from "../../state";

// TODO: clean this up when design makes a decision on proactive style
function buttonCssVarOverrides() {
    return () => {
        return {
            root: {
                "--button-bd": "unset",
                "--button-bg": "var(--mantine-color-dark-5)",
                "--button-height": "var(--button-height-sm)",
                "--button-hover": "var(--mantine-color-dark-6)",
            },
        };
    };
}

export function QuickActionButton({ value, ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    const {
        features: { isNewProactiveUI },
    } = useExpertAssistStore();

    const updatedProps = isNewProactiveUI ? { ...rest, vars: buttonCssVarOverrides() } : rest;

    return (
        <Button
            value={value}
            aria-label={value as string}
            c="var(--mantine-color-white)"
            data-testid={`QuickAction-${value as string}`}
            variant="default"
            {...updatedProps}
        >
            {value}
        </Button>
    );
}
