import { Button, Flex, Group, Radio, Text, Textarea } from "@mantine/core";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { Logger } from "@expert/logging";
import { useFeedbackDraftStore } from "../../store";
import classes from "../../styles.module.css";
import { type FeedbackIssueTypes, feedbackUIMapping } from "../../types";

interface FeedbackProps {
    logger: Logger;
    onSubmit: () => void;
    isLoading: boolean;
}

export function Feedback({ onSubmit, isLoading, logger: loggerProp }: FeedbackProps) {
    const { draft, setDraft, resetDraft } = useFeedbackDraftStore();
    const { dispatcher } = useReactAnalytics();
    const logger = loggerProp.child({ module: "Feedback" });

    const onChangeRadio = (value: string) => {
        setDraft({
            ...draft,
            issue: value as FeedbackIssueTypes,
        });
    };

    const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDraft({
            ...draft,
            text: event.currentTarget.value,
        });
    };

    const onClear = () => {
        resetDraft();
        void dispatcher.dispatchAction("Click", "ClearFeedback", {});
        logger.info("Feedback cleared");
    };

    return (
        <Flex direction="column">
            <Text size="sm" c="dark.3" mb="lg">
                {feedbackUIMapping.default.subtitle}
            </Text>
            <Radio.Group value={draft.issue} onChange={onChangeRadio}>
                <Group>
                    {feedbackUIMapping.default.radioGroup.map((label) => (
                        <Radio
                            size="xs"
                            key={label}
                            value={label}
                            label={label}
                            data-testid={`radio-feedback-${label}`}
                            fw={label === draft.issue ? 700 : 400}
                        />
                    ))}
                </Group>
            </Radio.Group>
            <Textarea
                classNames={{ input: classes.input }}
                value={draft.text}
                minRows={7.5}
                maxRows={7.5}
                mt="lg"
                autosize
                onChange={onChangeText}
                placeholder={feedbackUIMapping.default.textAreaPlaceholder[draft.issue]}
                data-testid="feedback-textarea"
                disabled={isLoading}
            />
            <Text size="xs" c="dark.3" mt="xs" mb="xs">
                {feedbackUIMapping.default.bottomText}
            </Text>
            <Flex direction="row" justify="flex-end" gap={{ base: "sm", sm: "lg" }}>
                <Button
                    className={classes.clearButton}
                    variant="subtle"
                    color="white"
                    size="compact-lg"
                    td="underline"
                    onClick={onClear}
                    disabled={!draft.text || isLoading}
                    data-testid="clear-feedback-button"
                >
                    Clear
                </Button>
                <Button
                    variant="filled"
                    color="primary.5"
                    size="compact-lg"
                    onClick={onSubmit}
                    disabled={!draft.text || isLoading}
                    data-testid="submit-feedback-button"
                    loading={isLoading}
                >
                    Submit
                </Button>
            </Flex>
        </Flex>
    );
}
