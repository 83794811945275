export const failureMessages = {
    botFailure: {
        text: "Sorry, something’s not right on my end 😕 Try again in a bit.",
        type: "Default",
    },
    connectionFailure: {
        text: "Sorry, things are running a little slow right now. Hang tight!",
        type: "Connection",
    },
} as const satisfies Record<
    `${string}Failure`,
    {
        text: string;
        type: string;
    }
>;

export type FailureReason = keyof typeof failureMessages;
