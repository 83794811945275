import { Global, css } from "@emotion/react";

const baseFontsUrl = "https://assets.asurion-ui.my.asurion53.com/fonts";

const globalStyles = css`
    html,
    #root,
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    @font-face {
        font-family: Apercu;
        font-weight: 700;
        font-display: swap;
        src:
            url("${baseFontsUrl}/Apercu-Bold-Pro.woff2") format("woff2"),
            url("${baseFontsUrl}/Apercu-Bold-Pro.woff") format("woff");
    }

    @font-face {
        font-family: Apercu;
        font-display: swap;
        font-weight: 300;
        src:
            url("${baseFontsUrl}/Apercu-Light-Pro.woff2") format("woff2"),
            url("${baseFontsUrl}/Apercu-Light-Pro.woff") format("woff");
    }

    @font-face {
        font-family: Apercu;
        font-display: swap;
        font-weight: 400;
        src:
            url("${baseFontsUrl}/Apercu-Regular-Pro.woff2") format("woff2"),
            url("${baseFontsUrl}/Apercu-Regular-Pro.woff") format("woff");
    }
    @font-face {
        font-family: ApercuMono;
        font-display: swap;
        font-weight: 400;
        src:
            url("${baseFontsUrl}/ApercuMono-Regular-Pro.woff2") format("woff2"),
            url("${baseFontsUrl}/ApercuMono-Regular-Pro.woff") format("woff");
    }
`;

export function GlobalStyles() {
    return <Global styles={globalStyles} />;
}
