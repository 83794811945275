import { createContext, useContext } from "react";

export interface FeedbackContextValue {
    expertFeedbackUrl: string;
}

const FeedbackContext = createContext<FeedbackContextValue | undefined>(undefined);

export function FeedbackContextProvider({ children, ...value }: React.PropsWithChildren<FeedbackContextValue>) {
    return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>;
}

export function useFeedbackContext(): FeedbackContextValue {
    const result = useContext(FeedbackContext);
    if (result === undefined) throw new Error("Hook used outside of FeedbackContextProvider");
    return result;
}
