import { useQuery } from "@tanstack/react-query";
import { getDefaultMessages } from "../api";
import { useContentfulClient } from "../ContentfulClientProvider";

// TODO: add specific type for defaultMessages in a new shared types package
// The intended type is ExpertAssistMessage[]
export const useDefaultMessages = (defaultMessages: unknown, listName?: string) => {
    const contentfulClient = useContentfulClient();
    const { data } = useQuery({
        queryKey: [`message-list`],
        queryFn: async () => await getDefaultMessages(contentfulClient, listName),
    });

    return data?.messages ?? defaultMessages;
};
