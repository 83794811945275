import type { ExpertAssistMessage, SendMessageProps, SendMessageType } from "@soluto-private/expert-workspace-timeline";
import { addFailureMessage, addMessage } from "@soluto-private/expert-workspace-timeline";
import type { Logger } from "@expert/logging";
import { trackFullStoryEvent } from "@expert/monitoring";
import { processMessage, setDeviceInfo, type ReactiveMessagePayload } from "@expert/solve-tools";
import { sendBusinessEvent } from "../analytics";
import { type GaiaErrorResponse, gaiaClientCache } from "../gaia";
import { getSessionDetails, getToken, getEaLogger } from "../state";

const onSuccess = (payload: ReactiveMessagePayload, sessionId: string, logger: Logger, callSid?: string) => {
    const messageType = "Search";
    const { response: updatedResponse, analytics } = processMessage(payload);
    sendBusinessEvent({
        eventName: "MessageReceived",
        attributes: { senderType: "bot", messageType, messageId: payload.messageId, ...analytics },
    });
    trackFullStoryEvent("ExpertAssistMessageReceived", { sessionId, callSid, messageType, ...analytics });

    if (payload.externalDevices) {
        setDeviceInfo(payload.externalDevices, logger);
    }

    addMessage({
        id: payload.messageId,
        text: updatedResponse,
        type: messageType,
        isUnread: true,
    });
};

const onFailure = ({ error, messageId, messageType }: GaiaErrorResponse) => {
    sendBusinessEvent({
        eventName: "MessageError",
        attributes: { errorMessage: error, messageId, messageType },
    });
    addFailureMessage("botFailure");
};

export const sendMessageToBot: SendMessageType = async ({ text }: SendMessageProps) => {
    const loggerWithContext = getEaLogger().child({ module: "sendMessageToBot" });
    const message: ExpertAssistMessage = {
        text,
        type: "Expert",
        id: crypto.randomUUID(),
        isUnread: false,
    };
    addMessage(message);

    const token = getToken();
    const { sessionId, callSid, expertId, partner, lob } = getSessionDetails();

    sendBusinessEvent({
        eventName: "MessageSent",
        attributes: { senderType: "expert", messageType: message.type, messageId: message.id },
    });
    trackFullStoryEvent("ExpertAssistMessageSent", { sessionId, callSid, partner, lob });
    const botMessage = {
        expertId,
        sessionId,
        callSid,
        messageId: message.id,
        message: text,
    };
    try {
        const data = await gaiaClientCache.getClient(token).postExpertMessage(botMessage);
        if (!data.response) {
            const errorMsg = "No response given from orchestrator fetch";
            loggerWithContext.error(data, errorMsg);
            onFailure({ errorMsg, messageId: message.id, messageType: message.type });
            return;
        }
        loggerWithContext.info({ sent: botMessage, received: data }, "Message sent and received from Bot");
        onSuccess(data as ReactiveMessagePayload, sessionId, loggerWithContext, callSid);
    } catch (err) {
        loggerWithContext.error(err, "Error occurred while sending message to GAIA orchestrator");
        onFailure({ error: err ? String(err) : "Unknown error", messageId: message.id, messageType: message.type });
    }
};
