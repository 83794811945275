import type { Logger } from "@expert/logging";
import { ActionIcon, CloseButton, Dialog, Flex, Group, Textarea, Title, Transition, rem } from "@mantine/core";
import { useClickOutside, useDebouncedValue, useElementSize, useMouse, usePrevious } from "@mantine/hooks";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { useEffect, useState } from "react";
import { ColorPalette, DragIcon } from "../../assets";
import type { ColorGroup, Coordinates } from "../../state";
import {
    noteColorGroups,
    projectionToCoordinates,
    useAreCoordinatesWithinBounds,
    useExpertNoteStore,
    useRepositionForBoundsChange,
} from "../../state";
import { ColorCircles } from "./ColorCircles";
import classes from "./NoteDialog.module.css";

interface NoteDialogProps {
    logger: Logger;
    isOpen: boolean;
    onClose: VoidFunction;
}

const placeholder =
    "Use this as a personal scratchpad—it’ll reset when you log out and won’t save to customer accounts. Never enter PII.";

let isMouseDown = false;
const pos = { x: 100, y: 100 };
const projectedPos = { x: 100, y: 100 };
let dragAnchorOffset: { x: number; y: number } = { x: 0, y: 0 };

const hasDimensions = (coord: Coordinates | null) => !!(coord && coord.x2 - coord.x1 > 0 && coord.y2 - coord.y1 > 0);

const typeDebounceWaitMs = 10000;

export function NoteDialog({ isOpen = false, onClose, logger }: NoteDialogProps) {
    const {
        color,
        setColor,
        note,
        setNote,
        notepadSize,
        setNotepadSize,
        notepadLocation,
        setNotepadLocation,
        draggableBounds,
    } = useExpertNoteStore();
    const [debouncedNote] = useDebouncedValue(note, typeDebounceWaitMs);
    const previousDebouncedNote = usePrevious(debouncedNote);
    const { dispatcher } = useReactAnalytics();
    const { ref: dialogRef, width: dialogWidth, height: dialogHeight } = useElementSize<HTMLDivElement>();
    const backgroundColor = color[0];
    const textColor = color[1];
    const scrollbarColor = color[2];
    const [isColorPaletteOpened, setIsColorPaletteOpened] = useState(false);

    const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNote(event.currentTarget.value);
    };
    const handleSetColor = (colorGroup: ColorGroup) => {
        logger.child({ module: "NoteDialog" }).info("Notepad color changed");
        setColor(colorGroup);
    };

    const { x: mouseX, y: mouseY } = useMouse();
    const [isClickOutside, setIsClickOutside] = useState(false);
    const clickOutsideRef = useClickOutside(() => {
        setIsClickOutside(true);
    });

    const isDragging = !isClickOutside && isMouseDown;
    if (isDragging) {
        if (dragAnchorOffset.x === 0 && dragAnchorOffset.y === 0 && dialogRef.current)
            dragAnchorOffset = {
                x: mouseX - dialogRef.current.offsetLeft,
                y: mouseY - dialogRef.current.offsetTop,
            };

        projectedPos.x = mouseX - dragAnchorOffset.x;
        projectedPos.y = mouseY - dragAnchorOffset.y;
    }
    const dialogCoordinates = projectionToCoordinates(dialogRef, { x: projectedPos.x, y: projectedPos.y });
    const notepadHasDimensions = hasDimensions(dialogCoordinates);
    const isDragBlocked = useAreCoordinatesWithinBounds(dialogCoordinates ?? null);

    useRepositionForBoundsChange(dialogCoordinates, pos, notepadHasDimensions);

    if (isDragging && !isDragBlocked) {
        pos.x = mouseX - dragAnchorOffset.x;
        pos.y = mouseY - dragAnchorOffset.y;
    }

    useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            isMouseDown = event.buttons === 1; // left-click
            if (!isMouseDown) dragAnchorOffset = { x: 0, y: 0 };
        };
        document.addEventListener("mousedown", handleMouseDown);
        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    useEffect(() => {
        const handleMouseUp = (event: MouseEvent) => {
            isMouseDown = event.buttons === 1;
            if (!isMouseDown) dragAnchorOffset = { x: 0, y: 0 };
        };
        document.addEventListener("mouseup", handleMouseUp);
        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [setNotepadLocation]);

    useEffect(() => {
        pos.x = notepadLocation.x;
        pos.y = notepadLocation.y;
    }, [notepadLocation]);

    useEffect(() => {
        if (dialogHeight === 0 && dialogWidth === 0) return;
        setNotepadSize({
            height: dialogHeight,
            width: dialogWidth,
        });
    }, [setNotepadSize, dialogHeight, dialogWidth]);
    const dialogMaxHeight = `calc(${rem(draggableBounds.y2 - draggableBounds.y1)} - ${rem(notepadLocation.y)})`;
    const dialogMaxWidth = `calc(${rem(draggableBounds.x2 - draggableBounds.x1)} - ${rem(notepadLocation.x)})`;

    useEffect(() => {
        if (previousDebouncedNote !== debouncedNote)
            void dispatcher.dispatchAction("Type", "TypeNotepad", {
                previousNoteLength: previousDebouncedNote?.length ?? 0,
                noteLength: debouncedNote.length,
                typeDebounceWaitMs,
            });
    }, [dispatcher, previousDebouncedNote, debouncedNote]);

    return (
        <Dialog
            data-testid="notepad-container"
            className={classes.dialog}
            ref={dialogRef}
            opened={isOpen}
            onClose={onClose}
            size="sm"
            zIndex="var(--mantine-priority-highest)"
            position={{ left: pos.x, top: pos.y }}
            display="flex"
            p="1rem"
            w={notepadSize.width}
            h={notepadSize.height}
            bg={backgroundColor}
            mah={dialogMaxHeight}
            maw={dialogMaxWidth}
        >
            <Flex
                className={classes.header}
                bg={backgroundColor}
                c={textColor}
                direction="row"
                justify="flex-end"
                align="center"
                mb="1rem"
            >
                <Title className={classes.title} size="h4" fw={400}>
                    Notepad
                </Title>
                <ActionIcon
                    data-testid="notepad-drag-icon"
                    style={{ cursor: "pointer" }}
                    ref={clickOutsideRef}
                    onMouseDownCapture={() => {
                        setIsClickOutside(false);
                    }}
                    variant="transparent"
                    h="1.5rem"
                    p=".125rem .19rem"
                >
                    <DragIcon
                        fill={textColor}
                        onMouseUp={() => {
                            setNotepadLocation({ x: pos.x, y: pos.y }, logger);
                        }}
                    />
                </ActionIcon>
                <CloseButton c={textColor} variant="transparent" ml="1rem" onClick={onClose} />
            </Flex>
            <Textarea
                className="fs-mask"
                classNames={{ input: classes.input }}
                minRows={10}
                onChange={onChangeText}
                placeholder={placeholder}
                styles={{
                    input: {
                        "--notepad-placeholder-color": textColor,
                        "--notepad-scrollbar-color": scrollbarColor,
                        backgroundColor,
                        color: textColor,
                        minHeight: notepadSize.height - 125,
                    },
                }}
                value={note}
            />
            <Group className={classes.colorPaletteGrid} onPointerLeave={() => setIsColorPaletteOpened(false)}>
                <Transition mounted={!isColorPaletteOpened} transition="fade" duration={200} timingFunction="linear">
                    {(styles) => (
                        <Flex className={`${classes.colorPaletteRow} ${classes.layer1}`} style={styles}>
                            <ColorPalette
                                className={classes.colorPaletteIcon}
                                fill={textColor}
                                onClick={() => {
                                    setIsColorPaletteOpened(true);
                                }}
                                cursor="pointer"
                            />
                        </Flex>
                    )}
                </Transition>
                <Transition mounted={isColorPaletteOpened} transition="fade" duration={200} timingFunction="linear">
                    {(styles) => (
                        <Flex className={`${classes.colorPaletteRow} ${classes.layer2}`} style={styles}>
                            <ColorCircles colors={noteColorGroups} onSetColor={handleSetColor} />
                        </Flex>
                    )}
                </Transition>
            </Group>
        </Dialog>
    );
}
