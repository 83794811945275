import { useQuery } from "@tanstack/react-query";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import { useExpertAssistStore } from "../state/store";
import { envName } from "./features";

export const useFeatureFlag = (featureFlag: string, context: Record<string, unknown> = {}, cacheTtl?: number) => {
    const { expertProfile } = useExpertAssistStore();
    const { data, isLoading, isError } = useQuery({
        queryKey: [featureFlag, ...Object.values(context)],
        queryFn: async (): Promise<boolean | undefined> =>
            getFeature<boolean>(
                featureFlag,
                {
                    environment: envName,
                    employeeId: expertProfile.id,
                    appName: "expert-assist-standalone",
                    source: "expert-assist",
                    ...context,
                },
                cacheTtl,
            ),
        refetchOnMount: false,
    });
    return { data, isLoading, isError };
};
