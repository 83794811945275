import { Pill } from "@mantine/core";
import { envName } from "../../../utils";

export function EnvironmentBadge(): JSX.Element | null {
    return (
        <Pill data-testid="env-tag" size="md" radius="sm" bg="asurion" fw="var(--mantine-font-weight-heavy)">
            {envName}
        </Pill>
    );
}
