export function BellIcon() {
    return (
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M4 11.5C4 7.08172 7.58172 3.5 12 3.5C16.4183 3.5 20 7.08172 20 11.5V18C20 18.8284 20.6716 19.5 21.5 19.5H2.5C3.32843 19.5 4 18.8284 4 18V11.5Z"
                fillRule="evenodd"
                stroke="#6E767D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M12 0.500977V3.50098"
                stroke="#6E767D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M14.4469 21.5C14.2103 22.6652 13.1858 23.5025 11.9969 23.5025C10.8079 23.5025 9.78347 22.6652 9.54688 21.5"
                stroke="#6E767D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M14.5 6.96875C16.0313 7.7285 17 9.29031 17 10.9998"
                stroke="#6E767D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
