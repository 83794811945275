import type { PartialFeedbackPayload } from "@soluto-private/expert-workspace-feedback";
import { useExpertAssistStore } from "../../state";

export const useFeedbackPayload = (): Omit<PartialFeedbackPayload, "caller"> => {
    const { sessionId, callSid, token, isProactiveEnabled, partner } = useExpertAssistStore();

    return {
        appName: "standalone-expert-assist",
        callSid,
        sessionId,
        partner,
        ssoAccessToken: token ?? "",
        extraData: { "Expert Assist Type": isProactiveEnabled ? "Proactive" : "Reactive" },
    };
};
