import { type Client, generateClient } from "@aws-amplify/api";
import { Amplify } from "@aws-amplify/core";
import { getToken } from "../state";
import { env } from "../utils/env";

Amplify.configure({
    API: {
        GraphQL: {
            endpoint: env.appsyncGraphqlUrl,
            region: env.appsyncRegion,
            defaultAuthMode: "lambda",
        },
    },
});

export const getClient = (): Client => {
    const token = getToken();
    if (!token) {
        throw new Error("GraphQL can’t be created due to empty token");
    }

    return generateClient({
        authMode: "lambda",
        authToken: token,
    });
};
