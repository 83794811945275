import type { AnyData, MetaModel } from "@soluto-private/eventualize";
import { getRootDispatcher } from "./dispatcher";
import type { IdentitiesObj } from "./identities";
import { getIdentities } from "./identities";

export interface BusinessEvent {
    eventName: string;
    attributes?: Record<string, unknown>;
    identities?: IdentitiesObj;
    meta?: AnyData | Partial<MetaModel>;
}

export const sendBusinessEvent = ({ eventName, attributes, identities, meta }: BusinessEvent) => {
    const [predefinedIdentities, extraData] = getIdentities();

    const combinedIdentities = {
        ...predefinedIdentities,
        ...identities,
    };
    void getRootDispatcher()
        .withMeta(meta ?? {})
        .withIdentities(combinedIdentities)
        .withExtra(extraData)
        .dispatchBusinessEvent(eventName, attributes);
};

export interface ActionEvent {
    actionType: string;
    actionId: string;
    attributes?: Record<string, unknown>;
    identities?: IdentitiesObj;
}

export const sendActionEvent = ({ actionType, actionId, attributes, identities }: ActionEvent) => {
    const [predefinedIdentities, extraData] = getIdentities();

    const combinedIdentities = {
        ...predefinedIdentities,
        ...identities,
    };
    void getRootDispatcher()
        .withIdentities(combinedIdentities)
        .withExtra(extraData)
        .dispatchAction(actionType, actionId, { ...attributes });
};
