import { Flex } from "@mantine/core";
import classes from "./BotIcon.module.css";

export function BotIconWrapper({ children }: { children: React.ReactNode }) {
    return (
        <Flex align="center" className={classes.botIconWrapper} justify="flex-start">
            {children}
        </Flex>
    );
}

export function LogoWrapper({ children }: { children: React.ReactNode }) {
    return (
        <Flex align="center" className={classes.logoWrapper} justify="flex-start">
            {children}
        </Flex>
    );
}
