export function AsurionLogoDark() {
    return (
        <svg fill="none" height="54" viewBox="0 0 54 54" width="54" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27.171" cy="27.171" fill="url(#paint0_linear_8829_18541)" r="26.171" />
            <path
                d="M33.5516 38.2312H38.4808V27.0002C38.4808 20.6359 33.3021 15.5195 27.0002 15.5195C20.6983 15.5195 15.5195 20.6359 15.5195 27.0002C15.5195 33.3644 20.6983 38.4808 27.0002 38.4808C29.184 38.4808 31.6174 37.8569 33.5516 35.6731V38.2312ZM27.0002 33.5516C23.3813 33.5516 20.4487 30.6191 20.4487 27.0002C20.4487 23.3813 23.3813 20.4487 27.0002 20.4487C30.6191 20.4487 33.5516 23.3813 33.5516 27.0002C33.5516 30.6191 30.6191 33.5516 27.0002 33.5516Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_8829_18541"
                    x1="27.171"
                    x2="27.171"
                    y1="53.3419"
                    y2="1"
                >
                    <stop stopColor="#6700B8" />
                    <stop offset="1" stopColor="#A90AD3" />
                </linearGradient>
            </defs>
        </svg>
    );
}
