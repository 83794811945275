import type { CSSVariablesResolver, MantineThemeOverride } from "@mantine/core";
import { createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import { colorOverrides } from "./overrides/colors";
import { componentOverrides } from "./overrides/components";

export const mantineTheme: MantineThemeOverride = createTheme({
    primaryColor: "asurion",
    primaryShade: {
        dark: 5,
        light: 5,
    },
    colors: colorOverrides,
    fontFamily: "Apercu",
    fontFamilyMonospace: "ApercuMono",
    components: componentOverrides,
    lineHeights: {
        xs: "1.2",
        sm: "1.2",
        md: "1.2",
        lg: "1.2",
        xl: "1.2",
    },
    fontSizes: {
        //👉👉 This 👈👈
        xxs: "0.75rem", // 12px AUI 0
        xs: "0.875rem", // 14px AUI 1
        sm: "1rem", // 16px AUI 2 (AUI 2??)
        md: "1.25rem", // 20px AUI 3
        lg: "1.5rem", // 24px AUI 4
        xl: "2rem", // 32px AUI 5
        "2xl": "2.5rem", // 40px AUI 6
        "3xl": "3rem", // 48px AUI 7
        "4xl": "3.75rem", // 60px AUI 8

        // 👉👉 Or This 👈👈
        I: "0.75rem", // 12px AUI 0
        II: "0.875rem", // 14px AUI 1
        III: "1rem", // 16px AUI 2
        IV: "1.25rem", // 20px AUI 3
        V: "1.5rem", // 24px AUI 4
        VI: "2rem", // 32px AUI 5
        VII: "2.5rem", // 40px AUI 6
        VIII: "3rem", // 48px AUI 7
        IX: "3.75rem", // 60px AUI 8
    },
    defaultRadius: "6px",
    spacing: {
        xl: "1.5rem",
    },
    cursorType: "pointer",
});

/** Enables creation of custom CSS variables (https://mantine.dev/styles/css-variables/#css-variables-resolver). */
export const resolver: CSSVariablesResolver = () => ({
    variables: {
        "--mantine-font-weight-light": "300",
        "--mantine-font-weight-normal": "400",
        "--mantine-font-weight-heavy": "700",
        "--mantine-priority-highest": "1000",
        "--mantine-priority-higher": "800",
        "--mantine-priority-high": "600",
        "--mantine-priority-low": "400",
        "--mantine-priority-lower": "200",
        "--mantine-priority-lowest": "0",
    },
    light: {
        // placeholder non-optional object
    },
    dark: {
        // placeholder non-optional object
    },
});
