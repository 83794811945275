import { type BeforeRequestHook } from "ky";
import { useExpertAssistStore } from "../../state";
import { featureHeaders } from "../../utils";

export function createHeadersHook(token: string): BeforeRequestHook {
    return ({ headers }) => {
        const { partner, lob, features } = useExpertAssistStore.getState();

        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
        // TODO: remove ozmo app-name once GAIA starts using features header
        headers.set(
            "x-app-name",
            features.isReactiveCallContextEnabled
                ? "expert-assist-call-context"
                : features.isOzmoMessagingEnabled
                  ? "expert-assist-ozmo"
                  : "expert-assist",
        );
        headers.set("x-features", featureHeaders.flatMap(([key, header]) => (features[key] ? header : [])).join(","));
        headers.set("x-partner-name", partner);
        // TODO - FF? Also, why it's not a option for mcafee... 🤦‍♀️
        if (partner !== "mcafee") {
            headers.set("x-line-of-business", lob);
        }
    };
}
