import { type GraphQLQuery } from "@aws-amplify/api";
import { getEaLogger, setIsProactiveEnabled, setSessionDetails } from "../../state";
import { standaloneFeatures } from "../../utils";
import { getClient } from "../client";
import { type EventType, updateExpert, type UpdateExpertMutation } from "./mutations";

export async function handleExpertUpdate(employeeId: string, eventType: EventType) {
    try {
        const result = await getClient().graphql<GraphQLQuery<UpdateExpertMutation>>({
            query: updateExpert,
            variables: { input: { employeeId, eventType } },
        });

        const expert = result.data.updateExpert;
        if (expert) {
            setIsProactiveEnabled(!!expert.isProactiveEnabled && standaloneFeatures.isProactiveEnabled); //set once on initial login instead of each subscription
            setSessionDetails(expert.sessionId, expert.callSid, expert.partner);
        }
    } catch (err: unknown) {
        getEaLogger()
            .child({ module: "updateExpert" })
            .error({ err }, "An error occurred during expertUpdate mutation");
    }
}
