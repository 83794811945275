import { MantineProvider } from "@mantine/core";
import type { PropsWithChildren } from "react";
import { mantineTheme, resolver } from "./mantine.theme.config";

export function GlobalThemeProvider({ children }: PropsWithChildren): JSX.Element {
    return (
        <MantineProvider cssVariablesResolver={resolver} defaultColorScheme="dark" theme={mantineTheme}>
            {children}
        </MantineProvider>
    );
}
