import * as React from "react";

export function NotesStarIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={23} height={24} fill="none" {...props}>
            <path
                stroke="#E6E6EB"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M9.27 19.925H1.885a.923.923 0 0 1-.923-.923V4.232c0-.51.413-.924.923-.924H14.81c.51 0 .924.413.924.923v4.616M4.655 5.154V1.461M8.347 5.154V1.461M12.04 5.154V1.461"
            />
            <path
                stroke="#E6E6EB"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="m17.307 12.013 1.421 3.296h2.765a.678.678 0 0 1 .477 1.184l-2.4 2.138 1.33 3.059a.725.725 0 0 1-1.032.906l-3.212-1.81-3.213 1.81a.725.725 0 0 1-1.031-.906l1.33-3.059-2.401-2.138a.677.677 0 0 1 .475-1.184h2.769l1.422-3.296a.739.739 0 0 1 1.3 0Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
