export function ResetChatIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.5002 16.4165H10.5835V19.3332"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.0977 17.444C19.3932 19.5096 17.3454 20.8059 15.177 20.5587C13.0086 20.3116 11.305 18.5877 11.0835 16.4165"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.6667 14.7497H20.5834V11.833"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.0693 13.7225C11.7738 11.6568 13.8216 10.3606 15.99 10.6077C18.1584 10.8549 19.862 12.5788 20.0835 14.75"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.91675 13.0832H8.08341L4.75008 16.4165V13.0832H2.25008C1.78984 13.0832 1.41675 12.7101 1.41675 12.2498V2.24984C1.41675 1.7896 1.78984 1.4165 2.25008 1.4165H18.0834C18.5437 1.4165 18.9167 1.7896 18.9167 2.24984V8.9165"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
