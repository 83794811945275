import type { Subscription } from "../graphql";
import { useExpertAssistStore } from "./store";
import type { Subscriptions } from "./types";

export const setSessionDetails = (sessionId: string, callSid?: string, partner?: string) => {
    useExpertAssistStore.getState().setSessionDetails(sessionId, callSid, partner);
};

export const setIsProactiveEnabled = (isProactiveEnabled: boolean) => {
    useExpertAssistStore.getState().setIsProactiveEnabled(isProactiveEnabled);
};

export const setSubscription = (id: keyof Subscriptions, subscription?: Subscription) => {
    useExpertAssistStore.getState().setSubscription(id, subscription);
};
