import type { ErrorInfo } from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "../pages";
import { getEaLogger } from "../state";

const logError = (error: Error, info: ErrorInfo) => {
    const localLogger = getEaLogger().child({ module: "ErrorBoundaryLayout" });
    localLogger.error({ error, info }, "Unhandled Error was caught in global error boundary");
};

export function ErrorBoundaryLayout() {
    return (
        <ErrorBoundary fallbackRender={ErrorPage} onError={logError}>
            <Outlet />
        </ErrorBoundary>
    );
}
