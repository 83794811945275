import { useEffect } from "react";
import { useExpertNoteStore } from "../notepadStore";
import type { Coordinates } from "../types";
import { isWithinBottomBounds, isWithinLeftBounds, isWithinRightBounds, isWithinTopBounds } from "../utils";

export const useRepositionForBoundsChange = (
    dialogCoordinates: Coordinates | null,
    pos: { x: number; y: number },
    isOpen: boolean,
) => {
    const { draggableBounds } = useExpertNoteStore();

    useEffect(() => {
        if (!dialogCoordinates) return;

        if (!isWithinLeftBounds(dialogCoordinates, draggableBounds)) {
            pos.x = draggableBounds.x1;
        }
        if (!isWithinTopBounds(dialogCoordinates, draggableBounds)) {
            pos.y = draggableBounds.y1;
        }
        if (!isWithinRightBounds(dialogCoordinates, draggableBounds)) {
            const modalWidth = dialogCoordinates.x2 - dialogCoordinates.x1;
            pos.x = draggableBounds.x2 - modalWidth;
        }
        if (!isWithinBottomBounds(dialogCoordinates, draggableBounds)) {
            const modalHeight = dialogCoordinates.y2 - dialogCoordinates.y1;
            pos.y = draggableBounds.y2 - modalHeight;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draggableBounds, isOpen]); // only reposition if draggable bounds are changed from viewport resize (or notepad is opened with dimensions)
};
