import { Flex } from "@mantine/core";
import { useMergedRef } from "@mantine/hooks";
import classes from "./MessageBubble.module.css";
import type { MessageBubbleProps } from "./types";

export function MessageBubble({
    type,
    isExpanded,
    shouldShowProactive,
    isLastProactiveMessage,
    tag,
    isNewProactiveUI,
    overflowRef,
    messageIntersectionRef,
    children,
}: MessageBubbleProps) {
    const mergedRef = useMergedRef(overflowRef, messageIntersectionRef);

    let bubbleClass: string;
    let variant = "reactive";

    switch (type) {
        case "Connection":
        case "Search":
            bubbleClass = classes.reactiveBubble;
            break;
        case "Proactive":
            if (shouldShowProactive) {
                bubbleClass =
                    isExpanded || isNewProactiveUI
                        ? classes.proactiveBubbleExpanded
                        : classes.proactiveBubbleNotExpanded;
                variant = "proactive";
            } else {
                bubbleClass = classes.reactiveBubble;
            }
            break;
        case "Default":
            bubbleClass = isLastProactiveMessage && tag ? classes.proactiveBubbleExpanded : classes.reactiveBubble;
            variant = "proactive";
            break;
        case "SessionSummary":
            bubbleClass = classes.sessionSummaryBubble;
            break;
        case "Expert":
        case "QuickAction":
        default:
            bubbleClass = classes.expertBubble;
    }

    return (
        <Flex
            className={`${classes.bubble} ${bubbleClass}`}
            data-testid="message-bubble"
            ref={mergedRef}
            direction="column"
            data-variant={variant}
        >
            {children}
        </Flex>
    );
}
