import { type GraphQLSubscription } from "@aws-amplify/api";
import { addMessage, type ExpertAssistMessage } from "@soluto-private/expert-workspace-timeline";
import { trackFullStoryEvent } from "@expert/monitoring";
import { triggerTabNotification, triggerProactiveSound } from "@expert/proactive-notifications";
import { sendBusinessEvent } from "../../analytics";
import { getEaLogger, getSubscription, isAlreadySubscribed, setSubscription, useExpertAssistStore } from "../../state";
import { getClient } from "../client";
import {
    type FormattedMessage,
    type Message,
    onUpdateMessage,
    type OnUpdateMessageSubscription,
} from "./subscriptions";

const handleResponse = ({ formattedMessage, sessionId, callSid }: Message) => {
    const localLogger = getEaLogger().child({ module: "subscribeToMessage" });
    try {
        const parsedMessage = JSON.parse(formattedMessage) as FormattedMessage;
        const message: ExpertAssistMessage = {
            type: parsedMessage.messageType,
            text: parsedMessage.content,
            id: parsedMessage.messageId,
            isUnread: true,
        };

        sendBusinessEvent({
            eventName: "MessageReceived",
            attributes: {
                senderType: "bot",
                messageType: parsedMessage.messageType,
                messageId: parsedMessage.messageId,
            },
            identities: { SessionId: sessionId, CallSid: callSid },
        });
        trackFullStoryEvent("ExpertAssistMessageReceived", { sessionId, callSid, messageType: message.type });
        // NOTE: This gets messages from Search bot also,
        // but those messages are handled as part of the api response
        // see botMessages.ts for the api response handling
        if (message.type === "Proactive") {
            const isProactiveSoundEnabled = useExpertAssistStore.getState().features.isProactiveSoundEnabled;
            triggerTabNotification("Expert Assist", isProactiveSoundEnabled);
            triggerProactiveSound(isProactiveSoundEnabled);

            addMessage({ ...message, tag: "Solve suggestion" });

            const { timestamp, ...rest } = parsedMessage;
            localLogger.info(
                {
                    ...rest,
                    responseTimestamp: timestamp,
                },
                "Proactive message received",
            );
        }
    } catch (error: unknown) {
        throw new Error(`Could not parse UpdateMessageResponse formattedMessage: ${String(error)}`);
    }
};

export function subscribeToMessage(sessionId: string, callSid: string) {
    const localLogger = getEaLogger().child({ module: "subscribeToMessage" });
    try {
        if (isAlreadySubscribed("onUpdateMessage")) {
            getSubscription("onUpdateMessage")?.unsubscribe();
        }

        const subscription = getClient()
            .graphql<GraphQLSubscription<OnUpdateMessageSubscription>>({
                query: onUpdateMessage,
                variables: { sessionId, callSid },
            })
            .subscribe({
                next: (value: { data: OnUpdateMessageSubscription }) => {
                    const message = value.data.onUpdateMessage;
                    if (message) {
                        handleResponse(message);
                    }
                    return message;
                },
                error: (err: unknown) => {
                    localLogger.error({ err }, "An error occurred during onUpdateMessage mutation subscription");
                },
            });

        setSubscription("onUpdateMessage", subscription);
    } catch (err: unknown) {
        localLogger.error({ err }, "An error occurred during onUpdateMessage subscription");
    }
}
