import { Button, Flex, Stack, Text } from "@mantine/core";
import { type FallbackProps } from "react-error-boundary";
import { Header } from "../components";
import { LostAstronaut } from "../components/errors/LostAstronaut";
import { PageContainer } from "../components/page-layout";

export function ErrorPage({ resetErrorBoundary }: FallbackProps) {
    const onRefresh = () => {
        resetErrorBoundary();
        location.reload();
    };

    return (
        <PageContainer>
            <Header />
            <Flex justify="center" align="center" wrap="wrap" gap="lg" mih="100%">
                <Stack>
                    <Text fw="var(--mantine-font-weight-heavy)" c="dark.0">
                        Something’s not right on our end
                    </Text>
                    <Text fw="var(--mantine-font-weight-light)" c="dark.1" w="95%">
                        Try again in a bit, or see if refreshing helps.
                    </Text>
                    <Button data-testid="refresh-button" onClick={onRefresh} size="lg" variant="filled">
                        Refresh
                    </Button>
                </Stack>
                <LostAstronaut />
            </Flex>
        </PageContainer>
    );
}
