import { useTimeout } from "@mantine/hooks";

function autoScroll(clear: () => void, scrollRef?: React.RefObject<HTMLDivElement>) {
    // delay the scrolling so the bot has a chance to start typing before scrolling to the bottom
    const div = scrollRef?.current;
    div?.scrollTo({ top: div.scrollHeight, behavior: "smooth" });
    clear();
}

export function useAutoscroll(scrollRef?: React.RefObject<HTMLDivElement>) {
    const { start, clear } = useTimeout(() => autoScroll(clear, scrollRef), 100);

    return { start };
}
