export const successLottie = {
    v: "5.0.1",
    fr: 100,
    ip: 0,
    op: 250,
    w: 1500,
    h: 1500,
    ddd: 0,
    assets: [],
    layers: [
        {
            ind: 6,
            nm: "Shape Layer 4",
            ks: {
                p: {
                    a: 1,
                    k: [
                        {
                            t: 150.1,
                            s: [1246, 282, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [1224, 594, 0],
                        },
                        { t: 200.2, s: [1224, 594, 0] },
                    ],
                },
                a: { a: 0, k: [0, 0, 0] },
                s: {
                    a: 1,
                    k: [
                        {
                            t: 150.1,
                            s: [0, 0, 100],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [4, 4, 100],
                        },
                        {
                            t: 166.8,
                            s: [4, 4, 100],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [0, 0, 100],
                        },
                        { t: 200.2, s: [0, 0, 100] },
                    ],
                },
                r: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 250,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "sh",
                                    d: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, -414.214],
                                                [414.214, 0],
                                                [0, 414.214],
                                                [-414.214, 0],
                                            ],
                                            o: [
                                                [414.214, 0],
                                                [0, 414.214],
                                                [-414.214, 0],
                                                [0, -414.214],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, -750],
                                                [750, 0],
                                                [0, 750],
                                                [-750, 0],
                                                [0, -750],
                                            ],
                                            c: true,
                                        },
                                        hd: false,
                                    },
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [0.431, 0.98, 0.765, 1] },
                                    hd: false,
                                    o: { a: 0, k: 100 },
                                    r: 1,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [0, 0] },
                                    a: { a: 0, k: [0, 0] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: 0 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "Ellipse Path 1",
                            ln: "Ellipse-Path-1",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0] },
                            a: { a: 0, k: [0, 0] },
                            s: { a: 0, k: [100, 100] },
                            r: { a: 0, k: 0 },
                            o: {
                                a: 1,
                                k: [
                                    {
                                        t: 166.8,
                                        s: [100],
                                        i: { x: [0.667], y: [1] },
                                        o: { x: [0.333], y: [0] },
                                        e: [0],
                                    },
                                    { t: 200.2, s: [0] },
                                ],
                            },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "Ellipse 1",
                    ln: "Ellipse-1",
                    hd: false,
                },
            ],
            ln: "Shape-Layer-4",
        },
        {
            ind: 5,
            nm: "Shape Layer 5",
            ks: {
                p: {
                    a: 1,
                    k: [
                        {
                            t: 150.1,
                            s: [1246, 282, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [1366, 166, 0],
                        },
                        { t: 200.2, s: [1366, 166, 0] },
                    ],
                },
                a: { a: 0, k: [0, 0, 0] },
                s: {
                    a: 1,
                    k: [
                        {
                            t: 150.1,
                            s: [0, 0, 100],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [4, 4, 100],
                        },
                        {
                            t: 166.8,
                            s: [4, 4, 100],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [0, 0, 100],
                        },
                        { t: 200.2, s: [0, 0, 100] },
                    ],
                },
                r: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 250,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "sh",
                                    d: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, -414.214],
                                                [414.214, 0],
                                                [0, 414.214],
                                                [-414.214, 0],
                                            ],
                                            o: [
                                                [414.214, 0],
                                                [0, 414.214],
                                                [-414.214, 0],
                                                [0, -414.214],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, -750],
                                                [750, 0],
                                                [0, 750],
                                                [-750, 0],
                                                [0, -750],
                                            ],
                                            c: true,
                                        },
                                        hd: false,
                                    },
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [0.431, 0.98, 0.765, 1] },
                                    hd: false,
                                    o: { a: 0, k: 100 },
                                    r: 1,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [0, 0] },
                                    a: { a: 0, k: [0, 0] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: 0 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "Ellipse Path 1",
                            ln: "Ellipse-Path-1",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0] },
                            a: { a: 0, k: [0, 0] },
                            s: { a: 0, k: [100, 100] },
                            r: { a: 0, k: 0 },
                            o: {
                                a: 1,
                                k: [
                                    {
                                        t: 166.8,
                                        s: [100],
                                        i: { x: [0.667], y: [1] },
                                        o: { x: [0.333], y: [0] },
                                        e: [0],
                                    },
                                    { t: 200.2, s: [0] },
                                ],
                            },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "Ellipse 1",
                    ln: "Ellipse-1",
                    hd: false,
                },
            ],
            ln: "Shape-Layer-5",
        },
        {
            ind: 4,
            nm: "Shape Layer 3",
            ks: {
                p: {
                    a: 1,
                    k: [
                        {
                            t: 150.1,
                            s: [1246, 282, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [911, 350, 0],
                        },
                        { t: 200.2, s: [911, 350, 0] },
                    ],
                },
                a: { a: 0, k: [0, 0, 0] },
                s: {
                    a: 1,
                    k: [
                        {
                            t: 150.1,
                            s: [0, 0, 100],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [4, 4, 100],
                        },
                        {
                            t: 166.8,
                            s: [4, 4, 100],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [0, 0, 100],
                        },
                        { t: 200.2, s: [0, 0, 100] },
                    ],
                },
                r: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 250,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "sh",
                                    d: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, -414.214],
                                                [414.214, 0],
                                                [0, 414.214],
                                                [-414.214, 0],
                                            ],
                                            o: [
                                                [414.214, 0],
                                                [0, 414.214],
                                                [-414.214, 0],
                                                [0, -414.214],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, -750],
                                                [750, 0],
                                                [0, 750],
                                                [-750, 0],
                                                [0, -750],
                                            ],
                                            c: true,
                                        },
                                        hd: false,
                                    },
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [0.431, 0.98, 0.765, 1] },
                                    hd: false,
                                    o: { a: 0, k: 100 },
                                    r: 1,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [0, 0] },
                                    a: { a: 0, k: [0, 0] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: 0 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "Ellipse Path 1",
                            ln: "Ellipse-Path-1",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0] },
                            a: { a: 0, k: [0, 0] },
                            s: { a: 0, k: [100, 100] },
                            r: { a: 0, k: 0 },
                            o: {
                                a: 1,
                                k: [
                                    {
                                        t: 166.8,
                                        s: [100],
                                        i: { x: [0.667], y: [1] },
                                        o: { x: [0.333], y: [0] },
                                        e: [0],
                                    },
                                    { t: 200.2, s: [0] },
                                ],
                            },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "Ellipse 1",
                    ln: "Ellipse-1",
                    hd: false,
                },
            ],
            ln: "Shape-Layer-3",
        },
        {
            ind: 3,
            nm: "New_Plane",
            ks: {
                p: {
                    a: 1,
                    k: [
                        {
                            t: 80,
                            s: [615, 880, 0],
                            to: [269.857, -91.779, 0],
                            ti: [68, 210, 0],
                            i: { x: [0.833], y: [0.829] },
                            o: { x: [0.333], y: [0] },
                            e: [1190, 405, 0],
                        },
                        {
                            t: 116.7,
                            s: [1190, 405, 0],
                            to: [-103.5, -305, 0],
                            ti: [-26.681, -317.47, 0],
                            i: { x: [0.833], y: [0.899] },
                            o: { x: [0.167], y: [0.081] },
                            e: [658.668, 461.908, 0],
                        },
                        {
                            t: 133.4,
                            s: [658.668, 461.908, 0],
                            to: [96.75, 371.826, 0],
                            ti: [-178.16, 361.354, 0],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.167], y: [0.197] },
                            e: [1200, 320, 0],
                        },
                        { t: 163.4, s: [1200, 320, 0] },
                    ],
                },
                a: {
                    a: 1,
                    k: [
                        {
                            t: 50,
                            s: [564, 826, 0],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [741, 765, 0],
                        },
                        {
                            t: 80,
                            s: [741, 765, 0],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [642, 786, 0],
                        },
                        { t: 103.4, s: [642, 786, 0] },
                    ],
                },
                s: {
                    a: 1,
                    k: [
                        {
                            t: 50,
                            s: [80, 80, 100],
                            i: { x: [0.55], y: [1] },
                            o: { x: [0.385], y: [0] },
                            e: [100, 100, 100],
                        },
                        {
                            t: 70,
                            s: [100, 100, 100],
                            i: { x: [0.376], y: [0.139] },
                            o: { x: [0.359], y: [0] },
                            e: [80, 80, 100],
                        },
                        {
                            t: 90,
                            s: [80, 80, 100],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.127], y: [0.214] },
                            e: [20, 20, 100],
                        },
                        { t: 163.4, s: [20, 20, 100] },
                    ],
                },
                r: {
                    a: 1,
                    k: [
                        {
                            t: 80,
                            s: [0],
                            i: { x: [0.832], y: [0.461] },
                            o: { x: [0.621], y: [0] },
                            e: [-15.977],
                        },
                        {
                            t: 106.7,
                            s: [-15.977],
                            i: { x: [0.833], y: [0.861] },
                            o: { x: [0.413], y: [0.079] },
                            e: [-283.577],
                        },
                        {
                            t: 133.4,
                            s: [-283.577],
                            i: { x: [0.705], y: [0.886] },
                            o: { x: [0.167], y: [0.244] },
                            e: [-379.036],
                        },
                        {
                            t: 150.1,
                            s: [-379.036],
                            i: { x: [0.757], y: [1] },
                            o: { x: [0.413], y: [-6.015] },
                            e: [-377],
                        },
                        { t: 163.4, s: [-377] },
                    ],
                },
                o: {
                    a: 1,
                    k: [
                        {
                            t: 150.1,
                            s: [100],
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            e: [0],
                        },
                        { t: 163.4, s: [0] },
                    ],
                },
            },
            ao: 0,
            ip: 0,
            op: 250,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "gr",
                                    it: [
                                        {
                                            ty: "sh",
                                            d: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                    ],
                                                    v: [
                                                        [475.5, -168],
                                                        [-275.403, 166.387],
                                                        [-240.194, 386.403],
                                                        [-219, 240],
                                                        [-123.565, 296.419],
                                                        [-216, 223],
                                                    ],
                                                    c: false,
                                                },
                                                hd: false,
                                            },
                                        },
                                        {
                                            ty: "fl",
                                            c: { a: 0, k: [0.298, 0.329, 0.353, 1] },
                                            hd: false,
                                            o: { a: 0, k: 100 },
                                            r: 1,
                                        },
                                        {
                                            ty: "tr",
                                            p: { a: 0, k: [0, 0] },
                                            a: { a: 0, k: [0, 0] },
                                            s: { a: 0, k: [100, 100] },
                                            r: { a: 0, k: 0 },
                                            o: { a: 0, k: 100 },
                                            sk: { a: 0, k: 0 },
                                            sa: { a: 0, k: 0 },
                                        },
                                    ],
                                    nm: "Path 1",
                                    ln: "Path-1",
                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [0, 0] },
                                    a: { a: 0, k: [0, 0] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: 0 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "Shape 1",
                            ln: "Shape-1",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [750, 750] },
                            a: { a: 0, k: [19, 46] },
                            s: { a: 0, k: [124, 124] },
                            r: { a: 0, k: 0 },
                            o: { a: 0, k: 100 },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "Shape Layer 4",
                    ln: "Shape-Layer-4",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "gr",
                                    it: [
                                        {
                                            ty: "sh",
                                            d: 1,
                                            ks: {
                                                a: 0,
                                                k: {
                                                    i: [
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                    ],
                                                    o: [
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                        [0, 0],
                                                    ],
                                                    v: [
                                                        [488, -174],
                                                        [-460, 16],
                                                        [-276, 166],
                                                        [-240, 386],
                                                        [-122, 296],
                                                        [4, 404],
                                                        [488, -174],
                                                    ],
                                                    c: true,
                                                },
                                                hd: false,
                                            },
                                        },
                                        {
                                            ty: "fl",
                                            c: { a: 0, k: [0.431, 0.463, 0.49, 1] },
                                            hd: false,
                                            o: { a: 0, k: 100 },
                                            r: 1,
                                        },
                                        {
                                            ty: "tr",
                                            p: { a: 0, k: [0, 0] },
                                            a: { a: 0, k: [0, 0] },
                                            s: { a: 0, k: [100, 100] },
                                            r: { a: 0, k: 0 },
                                            o: { a: 0, k: 100 },
                                            sk: { a: 0, k: 0 },
                                            sa: { a: 0, k: 0 },
                                        },
                                    ],
                                    nm: "Path 1",
                                    ln: "Path-1",
                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [0, 0] },
                                    a: { a: 0, k: [0, 0] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: 0 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "Shape 1",
                            ln: "Shape-1",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [750, 750] },
                            a: { a: 0, k: [19, 46] },
                            s: { a: 0, k: [124, 124] },
                            r: { a: 0, k: 0 },
                            o: { a: 0, k: 100 },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "Shape Layer 3",
                    ln: "Shape-Layer-3",
                    hd: false,
                },
            ],
            ln: "New_Plane",
        },
        {
            ind: 2,
            nm: "Shape Layer 2",
            ks: {
                p: { a: 0, k: [700, 900] },
                a: { a: 0, k: [0, 0, 0] },
                s: { a: 0, k: [100, 100, 100] },
                r: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 250,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "sh",
                                    d: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-300, -124],
                                                [-65, 67],
                                                [395, -459],
                                            ],
                                            c: false,
                                        },
                                        hd: false,
                                    },
                                },
                                {
                                    ty: "st",
                                    c: { a: 0, k: [0.431, 0.98, 0.765, 1] },
                                    o: { a: 0, k: 100 },
                                    w: { a: 0, k: 88 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    d: [
                                        { n: "d", nm: "dash", v: { a: 0, k: 1001.598 } },
                                        { n: "g", nm: "gap", v: { a: 0, k: 1001.598 } },
                                        {
                                            n: "o",
                                            nm: "offset",
                                            v: {
                                                a: 1,
                                                k: [
                                                    {
                                                        t: 133.5,
                                                        s: [-1001.598],
                                                        i: { x: [0.174], y: [0.832] },
                                                        o: { x: [0.337], y: [0] },
                                                        e: [-1822.908],
                                                    },
                                                    { t: 206.7, s: [-1822.908] },
                                                ],
                                            },
                                        },
                                    ],
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [0, 0] },
                                    a: { a: 0, k: [0, 0] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: 0 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "Path 1",
                            ln: "Path-1",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [8, 0] },
                            a: { a: 0, k: [0, 0] },
                            s: { a: 0, k: [100, 100] },
                            r: { a: 0, k: 0 },
                            o: { a: 0, k: 100 },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "Shape 1",
                    ln: "Shape-1",
                    hd: false,
                },
            ],
            ln: "Shape-Layer-2",
        },
        {
            ind: 1,
            nm: "Shape Layer 1",
            ks: {
                p: { a: 0, k: [708, 900] },
                a: { a: 0, k: [0, 0, 0] },
                s: { a: 0, k: [100, 100, 100] },
                r: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 250,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "sh",
                                    d: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-300, -124],
                                                [-65, 67],
                                                [395, -459],
                                            ],
                                            c: false,
                                        },
                                        hd: false,
                                    },
                                },
                                {
                                    ty: "st",
                                    c: { a: 0, k: [0.2, 0.247, 0.282, 1] },
                                    o: { a: 0, k: 100 },
                                    w: { a: 0, k: 88 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    d: [
                                        { n: "d", nm: "dash", v: { a: 0, k: 1001.598 } },
                                        { n: "g", nm: "gap", v: { a: 0, k: 1001.598 } },
                                        {
                                            n: "o",
                                            nm: "offset",
                                            v: {
                                                a: 1,
                                                k: [
                                                    {
                                                        t: 133.5,
                                                        s: [-1001.598],
                                                        i: { x: [0.667], y: [1] },
                                                        o: { x: [0.167], y: [0] },
                                                        e: [-1963.131],
                                                    },
                                                    { t: 170.2, s: [-1963.131] },
                                                ],
                                            },
                                        },
                                    ],
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [0, 0] },
                                    a: { a: 0, k: [0, 0] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: 0 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "Path 1",
                            ln: "Path-1",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0] },
                            a: { a: 0, k: [0, 0] },
                            s: { a: 0, k: [100, 100] },
                            r: { a: 0, k: 0 },
                            o: { a: 0, k: 100 },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "Shape 1",
                    ln: "Shape-1",
                    hd: false,
                },
            ],
            ln: "Shape-Layer-1",
        },
    ],
    markers: [],
};
