import { type LevelOrString, type Logger, pino } from "pino";
import { createWrite } from "./utils/pretty";
import { send } from "./utils/sendToBackend";

let rootLogger: Logger | undefined;

interface LoggerContextBase {
    module?: string;
}

const rootBindings: Record<string, unknown> = {};

interface InitLoggingProps {
    appName: string;
    logLevel: {
        api: LevelOrString;
        console: LevelOrString;
    };
}

export function initLogging({ appName, logLevel }: InitLoggingProps) {
    rootLogger = pino({
        browser: {
            write: createWrite(logLevel.console.toLowerCase()),
            transmit: {
                level: logLevel.api.toLowerCase(),
                send,
            },
        },
    });
    rootBindings.appName = appName;
}

export function getLogger<TContext extends LoggerContextBase = LoggerContextBase>(
    context: TContext & Record<string, string>,
) {
    if (!rootLogger) throw new Error("initLogging() not yet called");
    const loggerContext = {
        ...context,
        ...rootBindings,
    };
    return rootLogger.child(loggerContext);
}
