import { useRef } from "react";
import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { useSetDraggableBounds } from "@soluto-private/expert-workspace-notepad";
import { type Features, Timeline } from "@soluto-private/expert-workspace-timeline";
import { sendMessageToBot } from "../../bot-messages";
import { useExpertAssistStore, useLoggerWithContextStore } from "../../state";
import { timelineFeatures } from "../../utils";
import { useFeatureFlag } from "../../utils/useFeatureFlag";

export function StandaloneTimeline() {
    const { callSid, partner, expertProfile, sessionId, isProactiveEnabled, lob } = useExpertAssistStore();
    const { loggerWithContext } = useLoggerWithContextStore();
    const containerRef = useRef<HTMLDivElement>(null);
    useSetDraggableBounds(containerRef);

    const { data: isOzmoToolEnabled } = useFeatureFlag("isOzmoToolEnabled", { partner });
    const { data: isOzmoMessagingEnabled } = useFeatureFlag("isOzmoMessagingEnabled", { partner, lob });
    const { data: isMultiModalEnabled } = useFeatureFlag("isMultiModalEnabled", { partner });
    const { data: isNewProactiveUI } = useFeatureFlag("isNewProactiveUI");
    const { data: isAutoCompleteEnabled } = useFeatureFlag("isAutoCompleteEnabled");
    const { data: isReactiveCallContextEnabled } = useFeatureFlag("isReactiveCallContextEnabled", { partner });

    const updatedFeatures: Features = {
        ...timelineFeatures,
        isProactiveEnabled,
        isNewProactiveUI: isNewProactiveUI ?? timelineFeatures.isNewProactiveUI,
        isOzmoToolEnabled: isOzmoToolEnabled ?? timelineFeatures.isOzmoToolEnabled,
        isOzmoMessagingEnabled: isOzmoMessagingEnabled ?? timelineFeatures.isOzmoMessagingEnabled,
        isMultiModalEnabled: isMultiModalEnabled ?? timelineFeatures.isMultiModalEnabled,
        isAutoCompleteEnabled: isAutoCompleteEnabled ?? timelineFeatures.isAutoCompleteEnabled,
        isReactiveCallContextEnabled: isReactiveCallContextEnabled ?? timelineFeatures.isReactiveCallContextEnabled,
    };

    return (
        <AnalyticsProvider
            dispatcher={(dispatcher) =>
                dispatcher
                    .withIdentities({
                        ...(callSid && { CallSid: callSid }),
                        ...(expertProfile.id && { ExpertId: expertProfile.id }),
                        ...(sessionId && { SessionId: sessionId }),
                    })
                    .withExtra({ tool: "expert-assist", partner, lob })
            }
        >
            <Timeline
                ref={containerRef}
                expertId={expertProfile.id}
                expertName={expertProfile.firstName || expertProfile.lastName}
                callSid={callSid}
                partner={partner}
                features={updatedFeatures}
                sendMessage={sendMessageToBot}
                sessionId={sessionId}
                logger={loggerWithContext}
            />
        </AnalyticsProvider>
    );
}
