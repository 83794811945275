import { useEffect, useState } from "react";
import { useEventListener, useMergedRef, useWindowEvent } from "@mantine/hooks";
import { useAutoscroll } from "../../utils";

export const useScrollHandler = ({
    scrollRef,
    messages,
}: {
    scrollRef?: React.RefObject<HTMLDivElement>;
    messages: number;
}) => {
    const { start: startAutoscroll } = useAutoscroll(scrollRef);
    const [isUserScrolling, setIsUserScrolling] = useState(false);
    const [isScrollAtBottom, setIsScrollAtBottom] = useState(true);

    // Differentiates between user scrolls and 'browser'/incoming message scrolls
    const onScrollEndEventRef = useEventListener("scrollend", () => {
        if (isUserScrolling) {
            setIsUserScrolling(false);
        }

        const scrollHeight = scrollRef?.current?.scrollHeight ?? 0;
        const scrollTop = scrollRef?.current?.scrollTop ?? 0;
        const clientHeight = scrollRef?.current?.clientHeight ?? 0;

        const isAtBottomFloor = scrollHeight - Math.floor(scrollTop) === clientHeight;
        const isAtBottomCeil = scrollHeight - Math.ceil(scrollTop) === clientHeight;

        setIsScrollAtBottom(isAtBottomFloor || isAtBottomCeil);
    });

    const onWheelEventRef = useEventListener("wheel", () => {
        setIsUserScrolling(true);
    });

    useWindowEvent("keydown", (event) => {
        if (event.code === "ArrowUp" || event.code === "ArrowDown") {
            setIsUserScrolling(true);
        }
    });

    /* Autoscroll for a new incoming message if the user is not
       scrolling AND is already at the bottom of the timeline */
    useEffect(() => {
        if (!isUserScrolling && isScrollAtBottom) {
            startAutoscroll();
        }
    }, [scrollRef, scrollRef?.current?.scrollHeight, isUserScrolling, isScrollAtBottom, startAutoscroll, messages]);

    const mergedRef = useMergedRef(scrollRef, onScrollEndEventRef, onWheelEventRef);

    return { mergedRef, isScrollAtBottom };
};
