import { Button } from "@mantine/core";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { ColorGroup } from "../../state/types";
import classes from "./NoteDialog.module.css";

interface ColorCirclesProps {
    colors: ColorGroup[];
    onSetColor: (color: ColorGroup) => void;
}

export function ColorCircles({ colors, onSetColor }: ColorCirclesProps): JSX.Element {
    const { dispatcher } = useReactAnalytics();
    const colorCircles = colors.map((group) => (
        <Button
            className={classes.colorButton}
            key={group[0]}
            color={group[0]}
            size="compact-xs"
            onClick={() => {
                onSetColor(group);
                void dispatcher.dispatchAction("Click", "Notepad_UpdateColor", { color: group[0] });
            }}
        />
    ));
    return <>{colorCircles}</>;
}
