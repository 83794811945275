import { featureCache } from "./featureCache";
import { hashInfo } from "./hashInfo";

/*
 * key - feature key to fetch
 * context - context about the requester to determine the value of the key
 * cacheTtl - ttl of in-memory cache to reduce load on the feature toggle server (in seconds)
 */
export const getFeature = async <T>(
    key: string,
    context: Record<string, unknown> = {},
    cacheTtl = 60,
): Promise<T | undefined> => {
    try {
        const hashedInfo = await hashInfo(key + JSON.stringify(context));

        if (cacheTtl > 0 && featureCache.has(hashedInfo)) {
            return featureCache.get<T>(hashedInfo);
        }

        const response = await fetch(`https://feature.remoteexpert.anywhereexpert.us/v1/feature/${key}/fetch`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ context: { ...context } }),
        });

        const value = ((await response.json()) as { value: T }).value;
        featureCache.set(hashedInfo, value, cacheTtl);
        return value;
    } catch (error) {
        console.error("error fetching the feature key", error);
        return undefined;
    }
};
