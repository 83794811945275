export function BellMutedIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 23.501L23.5 0.500977"
                stroke="#FF5070"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 18.5C14 19.6046 13.1046 20.5 12 20.5C10.8954 20.5 10 19.6046 10 18.5"
                stroke="#6E767D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.946 6.226C14.8 6.151 14.655 6.065 14.5 6C14.5 4.61929 13.3807 3.5 12 3.5C10.6193 3.5 9.5 4.61929 9.5 6C7.07779 7.00925 5.5 9.37594 5.5 12V15.672"
                stroke="#6E767D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.32812 18.4999H20.5001C19.3956 18.4999 18.5001 17.6045 18.5001 16.4999V11.9999C18.4991 10.9721 18.253 9.95944 17.7821 9.0459"
                stroke="#6E767D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
