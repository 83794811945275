import { Menu, Switch, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { BellIcon, BellMutedIcon } from "../assets";

export function SoundNotificationsSwitch({ onClick }: { onClick: (isMuted: boolean) => void }) {
    const [isProactiveSoundMuted, setIsProactiveSoundMuted] = useLocalStorage<boolean>({
        key: "is-proactive-sound-muted",
        defaultValue: false,
    });

    return (
        <Menu.Item
            leftSection={isProactiveSoundMuted ? <BellMutedIcon /> : <BellIcon />}
            rightSection={
                <Switch
                    checked={!isProactiveSoundMuted}
                    labelPosition="left"
                    onChange={({ currentTarget: { checked } }) => {
                        onClick(!checked);
                        setIsProactiveSoundMuted(!checked);
                    }}
                />
            }
            variant="subtle"
            data-testid="sound-notifications-button"
        >
            <Text size="sm">Suggestion sound</Text>
        </Menu.Item>
    );
}
