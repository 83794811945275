export function triggerTabNotification(tabTitle: string, isProactiveSoundEnabled: boolean) {
    if (isProactiveSoundEnabled) {
        const flickerBrowserTab = setInterval(() => {
            document.title = document.title === tabTitle ? `(1) New message` : tabTitle;
        }, 1000);

        const onTabFocus = () => {
            if (flickerBrowserTab) {
                clearInterval(flickerBrowserTab);
                document.title = tabTitle;
                window.removeEventListener("focus", onTabFocus);
            }
        };

        window.addEventListener("focus", onTabFocus);
    }
}
