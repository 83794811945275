import { useDevices } from "@expert/contentful";
import type { MultipleActionName, MultipleActions } from "./types";

const DEFAULT_DEVICES_LIST = ["iPhone 13", "Galaxy S23", "Pixel 6", "Apple Watch"];
const TSNR_URL = "https://csp-prod-eastus2-trip-mo.it.att.com/portal/trip_mo/Dashboard_MO";

export function useCreateActions({
    setIsExpanded,
}: {
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}): Record<MultipleActionName, MultipleActions> {
    const devices = useDevices(DEFAULT_DEVICES_LIST);

    return {
        expandMessage: {
            value: "Show steps",
            analytics: {
                actionId: "ShowSteps",
            },
            onClick: () => {
                setIsExpanded(true);
            },
            isVisibleAfterClick: true,
        },
        neutralOrPositiveFeedback: {
            value: ["Issue resolved", "Still need help"],
            analytics: {
                actionId: "MessageFeedbackDetails",
            },
        },
        negativeFeedback: {
            value: "Wrong issue",
            analytics: {
                actionId: "MessageFeedbackDetails",
            },
        },
        tsnr: {
            value: "Open Trip instead",
            analytics: { actionId: "OpenTSNR" },
            onClick: () => {
                window.open(TSNR_URL, "_blank", "noopener,noreferrer");
            },
            isVisibleAfterClick: true,
        },
        devices: {
            value: devices,
            analytics: { actionId: "DeviceInfo" },
        },
    };
}
