export function ErrorIcon() {
    return (
        <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M8.66679 13.3333C11.6123 13.3333 14.0001 10.9455 14.0001 7.99998C14.0001 5.05446 11.6123 2.66665 8.66679 2.66665C5.72127 2.66665 3.33346 5.05446 3.33346 7.99998C3.33346 10.9455 5.72127 13.3333 8.66679 13.3333ZM8.66679 14.6666C12.3487 14.6666 15.3335 11.6819 15.3335 7.99998C15.3335 4.31808 12.3487 1.33331 8.66679 1.33331C4.98489 1.33331 2.00012 4.31808 2.00012 7.99998C2.00012 11.6819 4.98489 14.6666 8.66679 14.6666ZM8.00012 9.99998V3.99998H9.33346V9.99998H8.00012ZM8.66679 12C9.03498 12 9.33346 11.7015 9.33346 11.3333C9.33346 10.9651 9.03498 10.6666 8.66679 10.6666C8.2986 10.6666 8.00012 10.9651 8.00012 11.3333C8.00012 11.7015 8.2986 12 8.66679 12Z"
                fill="white"
                fillRule="evenodd"
            />
        </svg>
    );
}
