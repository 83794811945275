export function CheckIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#12B886" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8021 8.19526C18.0625 8.45561 18.0625 8.87772 17.8021 9.13807L11.1355 15.8047C10.8751 16.0651 10.453 16.0651 10.1927 15.8047L6.85932 12.4714C6.59898 12.2111 6.59898 11.7889 6.85932 11.5286C7.11967 11.2682 7.54178 11.2682 7.80213 11.5286L10.6641 14.3905L16.8593 8.19526C17.1197 7.93491 17.5418 7.93491 17.8021 8.19526Z"
                fill="white"
            />
        </svg>
    );
}
