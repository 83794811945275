import React, { useEffect, useMemo } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import { Home } from "../pages";
import { useExpertAssistStore, useLoggerWithContextStore } from "../state";
import { type OIDCUser, SSO_STORAGE_KEY } from "../auth";
import { RoutePath } from "./RoutePath";

interface ProtectedRouteProps {
    children: React.ReactNode;
}

function ProtectedRoute(props: ProtectedRouteProps) {
    const auth = useAuth();
    const { setToken, setExpertProfile } = useExpertAssistStore();
    const { loggerWithContext } = useLoggerWithContextStore();
    const localLogger = loggerWithContext.child({ module: "ProtectedRoute" });

    const oidcUser = useMemo(() => {
        try {
            const data = sessionStorage.getItem(SSO_STORAGE_KEY);
            return JSON.parse(data ?? "{}") as OIDCUser;
        } catch (err: unknown) {
            localLogger.error({ err }, "Error parsing employee profile");
            return {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) as OIDCUser;

    useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator) {
            try {
                void auth.signinRedirect({ nonce: crypto.randomUUID() });
            } catch (err: unknown) {
                localLogger.error({ err }, "Auth error occured");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    useEffect(() => {
        if (auth.isAuthenticated && auth.user?.access_token) {
            setToken(auth.user.access_token);
        }
    }, [auth, setToken]);

    useEffect(() => {
        if (auth.isAuthenticated) {
            setExpertProfile({
                firstName: oidcUser.profile.given_name,
                lastName: oidcUser.profile.family_name,
                email: oidcUser.profile.email ?? oidcUser.profile.sub,
                id: oidcUser.profile.employeeID,
            });
        }
    }, [auth, oidcUser, setExpertProfile]);

    switch (auth.activeNavigator) {
        case "signinRedirect":
        case "signinSilent":
            return <Navigate to={RoutePath.Landing} replace />;
        default:
            break;
    }

    //TODO: what causes this kind of error and how do we want to handle it?
    if (auth.error) {
        return <div>Auth Error Occurred</div>;
    }

    return <>{auth.isAuthenticated && props.children}</>;
}

export function ProtectedHome() {
    return (
        <ProtectedRoute>
            <Home />
        </ProtectedRoute>
    );
}
