import type { Coordinates } from "./types";

export const projectionToCoordinates = (
    ref: React.MutableRefObject<HTMLElement | null>,
    mousePos: { x: number; y: number },
) => {
    if (!ref.current) return null;
    const rect = ref.current.getBoundingClientRect();
    const coordinates = { x1: mousePos.x, y1: mousePos.y, x2: mousePos.x + rect.width, y2: mousePos.y + rect.height };
    return coordinates;
};

export const isWithinLeftBounds = (c: Coordinates, b: Coordinates) => c.x1 > b.x1;
export const isWithinTopBounds = (c: Coordinates, b: Coordinates) => c.y1 > b.y1;
export const isWithinRightBounds = (c: Coordinates, b: Coordinates) => c.x2 < b.x2;
export const isWithinBottomBounds = (c: Coordinates, b: Coordinates) => c.y2 < b.y2;
/**
 * determines if set of coordinates are a strict subset of bounds
 * @returns true if coordinates are bound by bounds, false otherwise
 */
export const areCoordinatesWithinBounds = (c: Coordinates, b: Coordinates) =>
    isWithinLeftBounds(c, b) && isWithinTopBounds(c, b) && isWithinRightBounds(c, b) && isWithinBottomBounds(c, b);
