import { useExpertNoteStore } from "../notepadStore";
import type { Coordinates } from "../types";
import { areCoordinatesWithinBounds } from "../utils";

/** return true if coordinates overlap any elements registered as drag blockers */
export const useAreCoordinatesWithinBounds = (dragCoordinates: Coordinates | null) => {
    const { draggableBounds } = useExpertNoteStore();
    if (!dragCoordinates) return true;

    if (!areCoordinatesWithinBounds(dragCoordinates, draggableBounds)) return true;

    return false; // drag is NOT blocked
};
