import type { AnalyticsEventModel, ExtendedEventData } from "@soluto-private/eventualize";
import ky from "ky";
import { env } from "../utils/env";

export const analyticsApi = ky.create({
    prefixUrl: `${env.baseUrl}/api/analytics/v1`,
    timeout: 30_000,
});

export const analyticsSink = async (event: AnalyticsEventModel<ExtendedEventData>) => {
    if (env.isProd) return;

    await analyticsApi.post("dispatch", {
        json: event,
    });
};
