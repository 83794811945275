import type { ContentfulClientApi } from "contentful";
import { createContext, useContext } from "react";

const Context = createContext<ContentfulClientApi<undefined> | null>(null);

export function ContentfulClientProvider({
    client,
    children,
}: React.PropsWithChildren<{ client: ContentfulClientApi<undefined> }>) {
    return <Context.Provider value={client}>{children}</Context.Provider>;
}

export const useContentfulClient = () => {
    const result = useContext(Context);
    if (result === null) throw new Error("Hook used outside of ContentfulClientProvider");
    return result;
};
