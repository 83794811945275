export function ToolboxIcon() {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7518_94280)">
                <g clipPath="url(#clip1_7518_94280)">
                    <path
                        d="M12.5 24.0005H22.5"
                        stroke="var(--mantine-color-dark-3)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M25.5 24.0005H35.5"
                        stroke="var(--mantine-color-dark-3)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 20.0005C12.5 19.4482 12.9477 19.0005 13.5 19.0005H34.5C35.0523 19.0005 35.5 19.4482 35.5 20.0005V32.0005C35.5 32.5528 35.0523 33.0005 34.5 33.0005H13.5C12.9477 33.0005 12.5 32.5528 12.5 32.0005V20.0005Z"
                        stroke="var(--mantine-color-dark-3)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.5 25.4995C25.5 26.3279 24.8284 26.9995 24 26.9995C23.1716 26.9995 22.5 26.3279 22.5 25.4995V22.9995H25.5V25.4995Z"
                        stroke="var(--mantine-color-dark-3)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M20 18.9995C20 16.7904 21.7909 14.9995 24 14.9995C26.2091 14.9995 28 16.7904 28 18.9995"
                        stroke="var(--mantine-color-dark-3)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_7518_94280">
                    <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                </clipPath>
                <clipPath id="clip1_7518_94280">
                    <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                </clipPath>
            </defs>
        </svg>
    );
}
