import { useEffect } from "react";
import { styled } from "styled-components";
import { RootAnalyticsProvider } from "@soluto-private/eventualize-react";
import { SidebarFeedbackButton, Header, PageContainer, ReusableMainContainer, StandaloneTimeline } from "../components";
import { useInitializers } from "../initializers";
import { useLoggerWithContextStore } from "../state";

const appContainerHeight = "100vh";
const MainContainer = styled(ReusableMainContainer)`
    margin: auto;
    height: ${appContainerHeight};
    overflow: hidden;
`;

export function Home() {
    useInitializers();
    const { loggerWithContext } = useLoggerWithContextStore();

    useEffect(() => {
        loggerWithContext.child({ module: "Home" }).info("Home page loaded");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RootAnalyticsProvider>
            <PageContainer>
                <Header />
                <MainContainer direction="column" $justify="flex-end">
                    <StandaloneTimeline />
                </MainContainer>
                <SidebarFeedbackButton />
            </PageContainer>
        </RootAnalyticsProvider>
    );
}
