import { Flex } from "@mantine/core";
import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { useDefaultMessages } from "@expert/contentful";
import { forwardRef, useEffect, useRef } from "react";
import type { ExpertAssistMessage, TimelineProps } from "../../shared-types";
import { fallbackDefaultMessages, useExpertAssistStore } from "../../state";
import { MessageInput } from "../message-input";
import { TimelineList } from "../timeline-list";
import { useAutoscroll } from "../../utils";

export const Timeline = forwardRef<HTMLDivElement, TimelineProps>(function Timeline(
    {
        expertId,
        expertName,
        callSid,
        sendMessage,
        sessionId,
        features,
        partner,
        guide,
        isFocused = true,
        logger: loggerProp,
    }: TimelineProps,
    ref,
): JSX.Element {
    const {
        setSessionId,
        setCallSid,
        setDefaultMessages,
        setExpertId,
        setExpertName,
        setSendMessage,
        setFeatures,
        setPartner,
    } = useExpertAssistStore();
    const logger = loggerProp.child({ module: "Timeline" });

    const scrollRef = useRef<HTMLDivElement>(null);
    const { start: startAutoscroll } = useAutoscroll(scrollRef);

    const contentfulDefaultMessages = useDefaultMessages(fallbackDefaultMessages) as ExpertAssistMessage[];

    useEffect(() => {
        setSessionId(sessionId, logger);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSessionId, sessionId]);

    useEffect(() => {
        setPartner(partner, logger);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPartner, partner]);

    useEffect(() => {
        if (callSid) {
            setCallSid(callSid, logger);
            startAutoscroll(); // auto scroll for a new call
        }

        return () => {
            setCallSid(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCallSid, callSid]);

    useEffect(() => {
        setExpertId(expertId, logger);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setExpertId, expertId]);

    useEffect(() => {
        setExpertName(expertName);
    }, [setExpertName, expertName]);

    useEffect(() => {
        setSendMessage(sendMessage);
    }, [setSendMessage, sendMessage]);

    useEffect(() => {
        setFeatures(features);
    }, [setFeatures, features]);

    useEffect(() => {
        setDefaultMessages(contentfulDefaultMessages);
    }, [setDefaultMessages, contentfulDefaultMessages]);

    return (
        <AnalyticsProvider
            dispatcher={(analyticsDispatcher) =>
                analyticsDispatcher.withIdentities({ ExpertAssistSessionId: sessionId })
            }
        >
            <Flex
                align="center"
                data-testid="timeline-wrapper"
                direction="column"
                h="100%"
                justify="flex-end"
                style={{ overflow: "hidden" }}
                m="auto"
                ref={ref}
                w="100%"
            >
                <TimelineList logger={logger} sessionId={sessionId} scrollRef={scrollRef} isFocused={isFocused} />
                <MessageInput logger={logger} scrollRef={scrollRef} guide={guide} />
            </Flex>
        </AnalyticsProvider>
    );
});
