import { UnstyledButton } from "@mantine/core";
import { DisabledSendIcon, EnabledSendIcon } from "../../assets";
import classes from "./MessageInput.module.css";

type SendButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    isDisabled: boolean;
};

export function SendButton(props: SendButtonProps) {
    return (
        <UnstyledButton
            className={props.isDisabled ? classes.sendButtonDisabled : classes.sendButtonEnabled}
            data-testid="send-button"
            disabled={props.isDisabled}
            id="SendButton"
            onClick={props.onClick}
            type="submit"
            variant="flat"
        >
            {props.isDisabled ? <DisabledSendIcon /> : <EnabledSendIcon />}
        </UnstyledButton>
    );
}
