import { type Color, bold, createColors, italic } from "colorette";
import dayjs from "dayjs";
import type { Level, LevelOrString, WriteFn } from "pino";

interface LogRecord {
    module: string;
    msg: string;
    level: number;
    time: number;
    [prop: string]: unknown;
}

interface LevelConfig {
    levelName: Level;
    levelColor: Color;
}

const availableColors = createColors({ useColor: true });
const { white, bgRed, bgRedBright, yellow, green, blue, gray, cyan } = availableColors;

const levels: Record<number, LevelConfig> = {
    10: { levelName: "trace", levelColor: gray },
    20: { levelName: "debug", levelColor: blue },
    30: { levelName: "info", levelColor: green },
    40: { levelName: "warn", levelColor: yellow },
    50: { levelName: "error", levelColor: bgRedBright },
    60: { levelName: "fatal", levelColor: bgRed },
};

const timestampStyle = white;
const messageStyle = cyan;

export function createWrite(logLevel: LevelOrString) {
    function shouldWrite(level: number) {
        const levelConfig = Object.entries(levels).find(([_level, { levelName }]) => levelName === logLevel);
        if (!levelConfig) return true; // If log level is not set, write everything to console
        return parseInt(levelConfig[0]) <= level;
    }

    return function write({ time, level, msg, module, ...extra }: LogRecord) {
        if (!shouldWrite(level)) return;

        const timestamp = dayjs(time).format("HH:mm:ss.SSS");
        const styledTimestamp = timestampStyle(timestamp);

        const levelName = levels[level].levelName;
        const styledLevelName = bold(levels[level].levelColor(levelName.toUpperCase()));

        const styledModule = italic(gray(module));
        const styledMessage = messageStyle(msg);
        // Using console.log for all levels because we color level
        console.log(`${styledTimestamp} ${styledLevelName} [${styledModule}]: ${styledMessage}`, extra);
    } as WriteFn;
}
