import { env } from "../utils/env";

export const OIDC_SSO_CALLBACK = env.oidcSsoUrl;
export const OIDC_SSO_SCOPES = "openid email profile GAIA:aigateway:invoke";
export const OIDC_SSO_CLIENT_ID = env.oidcClientId;
export const OIDC_SSO_BASE = "https://ndcsso.asurion.com:9031";
export const OIDC_SSO_AUTHORIZATION_ENDPOINT = `${OIDC_SSO_BASE}/as/authorization.oauth2`;
export const OIDC_SSO_USERINFO_ENDPOINT = `${OIDC_SSO_BASE}/idp/userinfo.openid`;
export const OIDC_END_SESSION_ENDPOINT = `${OIDC_SSO_BASE}/idp/startSLO.ping`;
export const OIDC_TOKEN_ENDPOINT = `${OIDC_SSO_BASE}/as/token.oauth2`;

export const SSO_STORAGE_KEY = env.ssoStorageKey;
