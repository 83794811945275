export function PhoneActivationIcon() {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9154 14.3532V19.1865C12.9154 20.107 12.1692 20.8532 11.2487 20.8532H3.7487C2.82822 20.8532 2.08203 20.107 2.08203 19.1865V3.35319C2.08203 2.43272 2.82822 1.68652 3.7487 1.68652H8.33203"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.6562 2.27051V5.67717"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.4633 3.93164C17.6001 5.06809 17.9403 6.77744 17.3253 8.26255C16.7103 9.74766 15.2612 10.716 13.6537 10.716C12.0463 10.716 10.5972 9.74766 9.98219 8.26255C9.36717 6.77744 9.70738 5.06809 10.8442 3.93164"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
