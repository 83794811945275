import { type SVGProps } from "react";

export function CaretDown({ height = 24, width = 24, fill, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5892 15.0892C12.2637 15.4146 11.7361 15.4146 11.4107 15.0892L6.41067 10.0892C6.08523 9.76374 6.08523 9.2361 6.41067 8.91066C6.7361 8.58523 7.26374 8.58523 7.58918 8.91066L11.9999 13.3214L16.4107 8.91066C16.7361 8.58523 17.2637 8.58523 17.5892 8.91066C17.9146 9.2361 17.9146 9.76374 17.5892 10.0892L12.5892 15.0892Z"
                fill={fill ?? "var(--mantine-color-dark-0)"}
            />
        </svg>
    );
}
