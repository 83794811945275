import { AuthProvider } from "react-oidc-context";
import { UserManager } from "oidc-client-ts";
import {
    OIDC_END_SESSION_ENDPOINT,
    OIDC_SSO_AUTHORIZATION_ENDPOINT,
    OIDC_SSO_BASE,
    OIDC_SSO_CALLBACK,
    OIDC_SSO_CLIENT_ID,
    OIDC_SSO_SCOPES,
    OIDC_SSO_USERINFO_ENDPOINT,
    OIDC_TOKEN_ENDPOINT,
} from "./constants";

const userManager = new UserManager({
    authority: OIDC_SSO_AUTHORIZATION_ENDPOINT,
    client_id: OIDC_SSO_CLIENT_ID,
    redirect_uri: OIDC_SSO_CALLBACK,
    scope: OIDC_SSO_SCOPES,
    metadata: {
        issuer: OIDC_SSO_BASE,
        authorization_endpoint: OIDC_SSO_AUTHORIZATION_ENDPOINT,
        userinfo_endpoint: OIDC_SSO_USERINFO_ENDPOINT,
        end_session_endpoint: OIDC_END_SESSION_ENDPOINT,
        token_endpoint: OIDC_TOKEN_ENDPOINT,
    },
});

interface AuthenticationProviderProps {
    children?: React.ReactNode;
    redirectPath: string;
}

export function AuthenticationProvider({ children, redirectPath }: AuthenticationProviderProps) {
    const onSignInCallback = (): void => {
        window.history.replaceState({}, document.title, window.location.pathname);
        window.location.href = redirectPath;
    };

    return (
        <AuthProvider userManager={userManager} onSigninCallback={onSignInCallback}>
            {children}
        </AuthProvider>
    );
}
