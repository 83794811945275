import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { FeedbackIssueTypes } from "../types";

export interface Draft {
    text: string;
    issue: FeedbackIssueTypes;
}
interface FeedbackDraft {
    draft: Draft;
    setDraft: (draft: Draft) => void;
    resetDraft: () => void;
}

export const useFeedbackDraftStore = create<FeedbackDraft>()(
    devtools(
        immer((set) => ({
            draft: {
                text: "",
                issue: "General feedback",
            },
            setDraft: ({ issue, text }) => {
                set(
                    ({ draft }) => {
                        draft.issue = issue;
                        draft.text = text;
                    },
                    false,
                    "setDraft",
                );
            },
            resetDraft: () => {
                set(
                    ({ draft }) => {
                        draft.issue = "General feedback";
                        draft.text = "";
                    },
                    false,
                    "resetDraft",
                );
            },
        })),
    ),
);
