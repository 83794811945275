import { styled } from "styled-components";

export const PageContainer = styled.div`
    background: #1d2227;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`;

export const ReusableMainContainer = styled.div<{ direction: "column" | "row"; $justify?: string }>`
    position: fixed;
    display: flex;
    flex-direction: ${(props) => props.direction};
    justify-content: ${(props) => props.$justify ?? "center"};
    height: 100%;
    align-items: center;
    width: 100%;
`;
