import type { MessageType } from "@soluto-private/expert-workspace-timeline";

export interface FormattedMessage {
    messageId: string;
    content: string;
    timestamp: string;
    role: string;
    messageType: MessageType;
}

export interface Message extends FormattedMessage {
    __typename: "Message";
    sessionId: string;
    callSid: string;
    formattedMessage: string;
}

export interface OnUpdateMessageSubscription {
    onUpdateMessage?: Message | null;
}

export const onUpdateMessage = /* GraphQL */ `
    subscription OnUpdateMessage($sessionId: String, $callSid: String, $formattedMessage: String) {
        onUpdateMessage(sessionId: $sessionId, callSid: $callSid, formattedMessage: $formattedMessage) {
            sessionId
            timestamp
            callSid
            formattedMessage
            __typename
        }
    }
`;
