import { useEffect } from "react";
import { useFeatureFlag } from "../utils/useFeatureFlag";
import { useExpertAssistStore } from "../state";

export function useInitializeFeatures() {
    const { setFeatures, features, partner, expertProfile, lob } = useExpertAssistStore();
    const { data: isOzmoMessagingEnabled } = useFeatureFlag(
        "isOzmoMessagingEnabled",
        { partner, employeeId: expertProfile.id, lob },
        300,
    );
    const { data: isProactiveSoundEnabled } = useFeatureFlag("isProactiveSoundEnabled");

    useEffect(() => {
        if (features.isOzmoMessagingEnabled !== isOzmoMessagingEnabled && isOzmoMessagingEnabled !== undefined) {
            setFeatures({
                ...features,
                isOzmoMessagingEnabled: !!isOzmoMessagingEnabled,
            });
        }
    }, [isOzmoMessagingEnabled, setFeatures, features]);

    useEffect(() => {
        if (features.isProactiveSoundEnabled !== isProactiveSoundEnabled && isProactiveSoundEnabled !== undefined) {
            setFeatures({
                ...features,
                isProactiveSoundEnabled: !!isProactiveSoundEnabled,
            });
        }
    }, [isProactiveSoundEnabled, setFeatures, features]);
}
