import { getEaLogger } from "../../state";

export enum HttpErrorCode {
    ValidationError = 400,
    AuthFailed = 401,
    NotFound = 404,
    Error = 500,
}

const errorMessagesMap: Record<HttpErrorCode, string> = {
    [HttpErrorCode.ValidationError]:
        "Validation error when both sessionId and sessionGroupId are missing or if any required field is missing",
    [HttpErrorCode.AuthFailed]: "GAIA Authentication failed",
    [HttpErrorCode.NotFound]: "No session history",
    [HttpErrorCode.Error]: "GAIA Internal error processing request",
};

export const reportErrorResponse = async (code: HttpErrorCode, response: Response, payload: object): Promise<void> => {
    const message = errorMessagesMap[code] || errorMessagesMap[500];
    const localLogger = getEaLogger().child({ module: "errors" });

    if (code === HttpErrorCode.NotFound) {
        localLogger.info({ payload }, message);
        return;
    }

    let extraData = { ...payload };
    try {
        const result = (await response.json()) as unknown as object;
        extraData = { ...payload, ...result };
    } catch {
        /* do nothing, it's ok */
    }

    localLogger.error({ ...extraData, status: code, statusText: response.statusText }, message);
};

export const noSessionHistory = {
    messages: [],
};
