import { getSessionDetails } from "../state";

enum Identities {
    UserBrowserId = "UserBrowserId",
    SessionId = "SessionId",
    ExpertId = "ExpertId",
    CallSid = "CallSid",
}

export type IdentitiesObj = {
    [identity in Identities | string]?: string;
};

export const getIdentities = (): [IdentitiesObj, Record<string, unknown>] => {
    const { sessionId, expertId, callSid, partner, lob } = getSessionDetails();
    let identities: IdentitiesObj = {};
    if (sessionId) {
        identities = { ...identities, SessionId: sessionId };
    }
    if (expertId) {
        identities = { ...identities, ExpertId: expertId, EmpId: expertId };
    }
    if (callSid) {
        identities = { ...identities, CallSid: callSid };
    }
    return [identities, { partner, lob }];
};
