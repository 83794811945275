import { Radio, Group } from "@mantine/core";

interface RadioGroupProps {
    options: string[];
    currentSelectedOption?: string;
    isDisabled: boolean;
    label: string;
    onChange: (value: string) => void;
}

export function RadioGroup({ options, currentSelectedOption, isDisabled, label, onChange }: RadioGroupProps) {
    return (
        <Radio.Group
            label={label}
            onChange={(val) => {
                onChange(val);
            }}
            mb="xs"
            mt="xs"
            value={currentSelectedOption}
        >
            <Group mt="xs">
                {options.map((optionName) => (
                    <Radio disabled={isDisabled} key={optionName} label={optionName} size="xs" value={optionName} />
                ))}
            </Group>
        </Radio.Group>
    );
}
