export function LaunchOzmoIcon() {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9154 12.5832V18.4165C12.9154 19.337 12.1692 20.0832 11.2487 20.0832H3.7487C2.82822 20.0832 2.08203 19.337 2.08203 18.4165V2.58317C2.08203 1.6627 2.82822 0.916504 3.7487 0.916504H8.33203"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9167 10.4998C13.492 10.4998 13.9583 10.0335 13.9583 9.45817C13.9583 8.88287 13.492 8.4165 12.9167 8.4165C12.3414 8.4165 11.875 8.88287 11.875 9.45817C11.875 10.0335 12.3414 10.4998 12.9167 10.4998Z"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.9141 3.93544C14.9982 1.57621 10.8299 1.57621 7.91406 3.93544"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 6.5313C11.7011 5.15558 14.1322 5.15558 15.8333 6.5313"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
