import type { NotificationData } from "@mantine/notifications";

export const ErrorNotificationMap = {
    AuthenticationError: {
        message: "You don’t have access to this tool. If you think you should, reach out to your manager",
    },
    FeedbackSavedError: {
        message: "Oops, there was issue sending feedback. Try again—we saved your progress.",
    },
} as const satisfies Record<`${string}Error`, Pick<NotificationData, "title" | "message">>;
