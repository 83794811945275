import { notifications } from "@mantine/notifications";
import { reactAnalytics } from "@soluto-private/eventualize-react";
import { ErrorIcon } from "./errorIcon";
import { ErrorNotificationMap } from "./types";

export const showErrorNotification = (errorType: keyof typeof ErrorNotificationMap) => {
    const data = ErrorNotificationMap[errorType];
    const id = notifications.show({
        ...data,
        color: "red.8",
        icon: <ErrorIcon />,
        autoClose: 5000,
    });

    void reactAnalytics.dispatcher.dispatchUserAction("Notification_View", "NotificationsSystem", {
        notificationType: "Error",
        notificationSubType: errorType,
        notificationId: id,
        notificationText: data.message,
    });
};
