export function NotepadIcon() {
    return (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.893 12.32L8.73047 12.7725L9.18297 9.60999L17.3146 1.47833C17.7953 0.977452 18.5092 0.775609 19.1809 0.950664C19.8527 1.12572 20.3772 1.65031 20.5523 2.32205C20.7274 2.9938 20.5255 3.70769 20.0246 4.18833L11.893 12.32Z"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.0013 5.0835H3.08464C2.16416 5.0835 1.41797 5.82969 1.41797 6.75016V18.4168C1.41797 19.3373 2.16416 20.0835 3.08464 20.0835H14.7513C15.6718 20.0835 16.418 19.3373 16.418 18.4168V10.5002"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
