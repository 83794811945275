import type { MarkdownToJSX } from "markdown-to-jsx";
import { ImageZoomPan, OzmoLink } from "../components";

export const hyperMediaOverride: MarkdownToJSX.Overrides = {
    a: {
        component: OzmoLink,
    },
    img: {
        component: ImageZoomPan,
    },
};
