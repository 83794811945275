export function ToolboxHoverIcon() {
    return (
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="48" height="48" rx="24" fill="var(--mantine-color-asurion-5)" />
            <g clipPath="url(#clip0_3803_62531)">
                <g clipPath="url(#clip1_3803_62531)">
                    <path
                        d="M12.5 24.5005H22.5"
                        stroke="var(--mantine-color-gray-1)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M25.5 24.5005H35.5"
                        stroke="var(--mantine-color-gray-1)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 20.5005C12.5 19.9482 12.9477 19.5005 13.5 19.5005H34.5C35.0523 19.5005 35.5 19.9482 35.5 20.5005V32.5005C35.5 33.0528 35.0523 33.5005 34.5 33.5005H13.5C12.9477 33.5005 12.5 33.0528 12.5 32.5005V20.5005Z"
                        stroke="var(--mantine-color-gray-1)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.5 25.9995C25.5 26.8279 24.8284 27.4995 24 27.4995C23.1716 27.4995 22.5 26.8279 22.5 25.9995V23.4995H25.5V25.9995Z"
                        stroke="var(--mantine-color-gray-1)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M20 19.4995C20 17.2904 21.7909 15.4995 24 15.4995C26.2091 15.4995 28 17.2904 28 19.4995"
                        stroke="var(--mantine-color-gray-1)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_3803_62531">
                    <rect width="24" height="24" fill="white" transform="translate(12 12.5)" />
                </clipPath>
                <clipPath id="clip1_3803_62531">
                    <rect width="24" height="24" fill="white" transform="translate(12 12.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
