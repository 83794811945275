import type { Features } from "@soluto-private/expert-workspace-timeline";
import { env } from "./env";

export const envName = env.envName;
export const isProd = env.isProd;

export type StandaloneFeatures = Features & {
    isProactiveSoundEnabled: boolean;
};
export const timelineFeatures: Features = {
    isProactiveEnabled: true,
    isNewProactiveUI: false, // TODO: clean up once product/design decides on the version
    isOzmoToolEnabled: !isProd,
    isOzmoMessagingEnabled: !isProd,
    isMultiModalEnabled: false,
    isAutoCompleteEnabled: false,
    isReactiveCallContextEnabled: false,
};

export const standaloneFeatures = {
    ...timelineFeatures,
    isProactiveSoundEnabled: !isProd,
};
export const featureHeaders: [keyof typeof timelineFeatures, string][] = [
    ["isOzmoMessagingEnabled", "expert-assist-ozmo"],
    ["isReactiveCallContextEnabled", "expert-assist-call-context"],
];
