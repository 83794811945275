import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export interface SolveGuideStore {
    currentStepId?: string;
    setCurrentStepId: (currentStepId: string) => void;
    previousSteps: string[];
    setPreviousSteps: (previousSteps: string[]) => void;

    disableAutomaticOpen: boolean;
    setDisableAutomaticOpen: (value: boolean) => void;

    restartWithStep: (stepId: string) => void;
    reset: () => void;
}

export const useSolveGuideStore = create<SolveGuideStore>()(
    persist(
        immer(
            devtools(
                (set) => ({
                    currentStepId: undefined,
                    setCurrentStepId: (currentStepId: string) => {
                        set(
                            (state) => {
                                state.currentStepId = currentStepId;
                            },
                            false,
                            "setCurrentStepId",
                        );
                    },
                    previousSteps: [],
                    setPreviousSteps: (previousSteps: string[]) => {
                        set(
                            (state) => {
                                state.previousSteps = previousSteps;
                            },
                            false,
                            "setPreviousSteps",
                        );
                    },
                    disableAutomaticOpen: false,
                    setDisableAutomaticOpen: (value: boolean) => {
                        set(
                            (state) => {
                                state.disableAutomaticOpen = value;
                            },
                            false,
                            "setDisableAutomaticOpen",
                        );
                    },
                    restartWithStep: (stepId: string) => {
                        set((state) => {
                            if (state.currentStepId)
                                state.previousSteps = [...state.previousSteps, state.currentStepId];
                            state.currentStepId = stepId;
                        });
                    },
                    reset: () => {
                        set(
                            (state) => {
                                state.currentStepId = undefined;
                                state.previousSteps = [];
                                state.disableAutomaticOpen = false;
                            },
                            false,
                            "reset",
                        );
                    },
                    onConfirm: undefined,
                }),
                {
                    enabled: import.meta.env.MODE !== "production",
                    store: "solve-guide",
                    name: "solve-guide",
                },
            ),
        ),
        { name: "solve-guide-store", skipHydration: true },
    ),
);
