import { useState } from "react";
import { Flex, Menu as MantineMenu, MenuDivider, Space, Text, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { FeedbackModal } from "@soluto-private/expert-workspace-feedback";
import { SoundNotificationsSwitch } from "@expert/proactive-notifications";
import { sendActionEvent } from "../../analytics";
import { useExpertAssistStore, useLoggerWithContextStore } from "../../state";
import { isProd } from "../../utils";
import { type FeedbackNotificationType, useFeedbackPayload } from "../feedback";
import { CaretDown, Megaphone } from "../svgs";
import { DevToolsMenuItem } from "../config-selectors";
import { useFeatureFlag } from "../../utils/useFeatureFlag";
import classes from "./styles.module.css";

interface MenuProps {
    onClose: (type: FeedbackNotificationType) => void;
}

export function Menu({ onClose }: MenuProps) {
    const [menuDropdownOpened, setMenuDropdownOpened] = useState(false);
    const {
        token,
        expertProfile: { firstName, lastName },
        features,
    } = useExpertAssistStore();

    const payload = useFeedbackPayload();
    const { partner } = useExpertAssistStore();
    const { loggerWithContext } = useLoggerWithContextStore();
    const localLogger = loggerWithContext.child({ module: "Menu" });
    const { data: isFeedbackEnabled } = useFeatureFlag("isFeedbackEnabled", { partner });

    const [opened, { open, close }] = useDisclosure(false, {
        onOpen: () => {
            setMenuDropdownOpened(false);
            sendActionEvent({
                actionId: "ShareFeedback",
                actionType: "Click",
                attributes: { source: "Menu" },
            });
            localLogger.info("Feedback modal in menu opened");
        },
        onClose: () => {
            onClose("success");
        },
    });
    const onError = () => {
        onClose("error");
    };

    const onMenuClick = () => {
        sendActionEvent({
            actionId: "agent-profile-menu-view",
            actionType: "Click",
        });
    };

    const onSoundNotificationsClick = (isMuted: boolean) => {
        localLogger.info("Sound notifications toggled");
        sendActionEvent({
            actionId: "sound-notification-toggle",
            actionType: "Click",
            attributes: { isMuted },
        });
    };

    return (
        <div>
            <FeedbackModal
                isOpen={opened}
                onClose={close}
                onError={onError}
                logger={loggerWithContext}
                feedbackPayload={{ ...payload, caller: "StandaloneHeader", ssoAccessToken: token ?? "" }}
            />
            <MantineMenu
                closeOnClickOutside
                closeOnItemClick={false}
                opened={menuDropdownOpened}
                onChange={setMenuDropdownOpened}
            >
                <MantineMenu.Target>
                    <UnstyledButton
                        mr="lg"
                        size="sm"
                        variant="subtle"
                        data-testid="menu-trigger-button"
                        className={classes.menuButton}
                        onClick={onMenuClick}
                    >
                        <Flex align="center">
                            <div className={classes.icon}>
                                <Text size="sm" className={classes.menuText}>
                                    {(firstName || lastName).charAt(0)}
                                </Text>
                            </div>
                            <Space w="xs" />
                            <Text size="sm" data-testid="expert-name">
                                {firstName} {lastName}
                            </Text>
                            <Space w="xs" />
                            <CaretDown />
                        </Flex>
                    </UnstyledButton>
                </MantineMenu.Target>
                <MantineMenu.Dropdown maw="17.5rem">
                    {isFeedbackEnabled && (
                        <MantineMenu.Item
                            leftSection={<Megaphone />}
                            variant="subtle"
                            className={classes.menuItemButton}
                            onClick={open}
                            data-testid="share-feedback-button"
                        >
                            Share your feedback
                        </MantineMenu.Item>
                    )}
                    {features.isProactiveSoundEnabled && (
                        <SoundNotificationsSwitch onClick={onSoundNotificationsClick} />
                    )}
                    {!isProd && (
                        <>
                            <MenuDivider />
                            <MantineMenu.Label>Dev tools</MantineMenu.Label>
                            <DevToolsMenuItem />
                        </>
                    )}
                </MantineMenu.Dropdown>
            </MantineMenu>
        </div>
    );
}
