import { useEffect } from "react";
import { Button, Title } from "@mantine/core";
import { useAuth } from "react-oidc-context";
import { styled } from "styled-components";
import { showErrorNotification } from "../components/errors";
import { BreakpointMaxWidth } from "../breakpoints";
import { Header } from "../components/header";
import { PageContainer, ReusableMainContainer } from "../components/page-layout";
import { GetStartedAnimation } from "../components/svgs/GetStartedAnimation";
import { useLoggerWithContextStore } from "../state";

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.0625rem;
    width: 18rem;
    @media ${BreakpointMaxWidth.Medium} {
        margin-top: 10rem;
        margin-left: 3.125rem;
    }
`;

const RightContainer = styled.div`
    height: 100%;
    display: flex;
    @media ${BreakpointMaxWidth.Medium} {
        margin-left: -5rem;
    }
    @media ${BreakpointMaxWidth.Small} {
        margin-left: -9.5rem;
    }
`;

const MainContent = styled.div`
    display: flex;
    height: 45.4375rem;
    align-items: center;
    justify-content: center;
    @media ${BreakpointMaxWidth.Medium} {
        flex-direction: column;
        align-items: flex-start;
        max-width: 47.75rem;
    }
    @media ${BreakpointMaxWidth.Small} {
        max-width: 31.25rem;
    }
`;

export function LandingPage() {
    const auth = useAuth();
    const { loggerWithContext } = useLoggerWithContextStore();
    const localLogger = loggerWithContext.child({ module: "LandingPage" });

    const onVerifyAuthentication = async () => {
        try {
            await auth.signinRedirect({ nonce: crypto.randomUUID() });
        } catch (err) {
            localLogger.error(err, "Auth error occured");
        }
    };

    useEffect(() => {
        if (auth.error) {
            localLogger.error(`Auth error: ${auth.error.message}`);
            showErrorNotification("AuthenticationError");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.error]);

    return (
        <PageContainer>
            <Header />
            <ReusableMainContainer direction="row">
                <MainContent>
                    <LeftContainer>
                        <Title order={1} data-testid="description" fw="var(--mantine-font-weight-light)">
                            Here to help you solve faster
                        </Title>
                        <Button
                            data-testid="get-started-button"
                            onClick={onVerifyAuthentication}
                            size="lg"
                            variant="filled"
                        >
                            Get Started
                        </Button>
                    </LeftContainer>
                    <RightContainer>
                        <GetStartedAnimation />
                    </RightContainer>
                </MainContent>
            </ReusableMainContainer>
        </PageContainer>
    );
}
