import { FeedbackPortal } from "@soluto-private/expert-workspace-feedback";
import { useLoggerWithContextStore, useExpertAssistStore } from "../../state";
import { useFeatureFlag } from "../../utils/useFeatureFlag";
import { useFeedbackNotification } from "./useFeedbackNotification";
import { useFeedbackPayload } from "./useFeedbackPayload";

export function SidebarFeedbackButton() {
    const payload = useFeedbackPayload();
    const triggerNotification = useFeedbackNotification();
    const { loggerWithContext } = useLoggerWithContextStore();
    const { partner } = useExpertAssistStore();
    const { data: isFeedbackEnabled } = useFeatureFlag("isFeedbackEnabled", { partner });

    return (
        isFeedbackEnabled && (
            <FeedbackPortal
                payload={{
                    ...payload,
                    caller: "StandaloneTimeline",
                }}
                showNotification={triggerNotification}
                source="Sidebar"
                logger={loggerWithContext}
            />
        )
    );
}
