import { Flex, Group } from "@mantine/core";
import { useExpertAssistStore } from "../../state";
import { isProd } from "../../utils";
import { AsurionAssistLogo } from "../svgs";
import { type FeedbackNotificationType, useFeedbackNotification } from "../feedback";
import { Menu } from "./Menu";
import { EnvironmentBadge, PartnerBadge, LobBadge } from "./badges";
import classes from "./styles.module.css";

export function Header() {
    const {
        expertProfile: { firstName, lastName },
        token,
        lob,
        partner,
    } = useExpertAssistStore();
    const isAuthenticated = token && (firstName || lastName);
    const triggerNotification = useFeedbackNotification();

    return (
        <Flex direction="row" justify="space-between" data-testid="header" className={classes.header}>
            <Group align="flex-start">
                <AsurionAssistLogo />
                {!isProd && <EnvironmentBadge />}
                {!isProd && <PartnerBadge partner={partner} />}
                {!isProd && isAuthenticated && <LobBadge lob={lob} />}
            </Group>
            {isAuthenticated && (
                <Menu
                    onClose={(type: FeedbackNotificationType) => {
                        triggerNotification(type);
                    }}
                />
            )}
        </Flex>
    );
}
