import { getLogger, initLoggingTransmission } from "@expert/logging";
import { useExpertAssistStore, useLoggerWithContextStore } from "../state";
import { env } from "../utils/env";

const { setLoggerInstance } = useLoggerWithContextStore.getState();

export const initLoggerStoreSubscriptions = () => {
    useExpertAssistStore.subscribe(
        (state) => [state.token],
        ([token], [prevToken]) => {
            if (!prevToken && token) {
                initLoggingTransmission({ accessToken: token, collectorUrl: env.logCollectorUrl });
            }
        },
    );

    useExpertAssistStore.subscribe(
        (state) => [state.sessionId],
        ([sessionId], [previousSessionId]) => {
            if (sessionId !== previousSessionId) {
                setLoggerInstance(
                    getLogger({
                        sessionId,
                    }),
                );
            }
        },
        {
            fireImmediately: true,
        },
    );

    useExpertAssistStore.subscribe(
        (state) => state.callSid,
        (callSid, previousCallSid) => {
            const { loggerWithContext } = useLoggerWithContextStore.getState();
            if (callSid !== previousCallSid && callSid) {
                setLoggerInstance(
                    loggerWithContext.child({
                        callSid,
                    }),
                );
            }
        },
        {
            fireImmediately: true,
        },
    );
};
