import { getEaLogger } from "../state";
import { OrchestratorGaiaClient } from "./orchestrator";
import type { GaiaClient } from "./types";

export class GaiaClientCache {
    private _cachedClient: GaiaClient | null = null;

    public getClient(token: string): GaiaClient {
        if (!this._cachedClient) {
            this._cachedClient = new OrchestratorGaiaClient(token);
            getEaLogger().child({ module: "gaia-cache" }).info(`Init GaiaClient`);
        }

        return this._cachedClient;
    }

    public resetCache() {
        this._cachedClient = null;
    }
}

export const gaiaClientCache = new GaiaClientCache();
