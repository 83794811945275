import type { ExpertAssistMessage } from "@soluto-private/expert-workspace-timeline";
import { addMessages } from "@soluto-private/expert-workspace-timeline";
import { gaiaClientCache, type GetHistoryQuery, type HistoricalMessage } from "../gaia";
import { getEaLogger } from "../state";

export const fetchExistingMessages = async ({ sessionId, callSid, token }: { token: string } & GetHistoryQuery) => {
    try {
        const messages = await gaiaClientCache.getClient(token).getMessagesHistory({
            sessionId,
            callSid,
        });

        if (messages.length > 0) {
            const previousMessages = messages.map<ExpertAssistMessage>(
                ({ id, content, role, timestamp }: HistoricalMessage) => ({
                    text: content,
                    type: role === "assistant" ? "Search" : "Expert",
                    id,
                    isUnread: false,
                    timestamp,
                }),
            );

            addMessages(previousMessages);
        }
    } catch (error) {
        getEaLogger().child({ module: "fetchExistingMessages" }).error(error);
    }
};
