import { initAppcues, initDynatrace, initFullStory } from "@expert/monitoring";
import { sendActionEvent } from "../analytics";
import { env } from "../utils/env";

export function initMonitors() {
    initFullStory({
        orgId: env.fullstoryOrgId,
        isDevMode: !env.isProd,
        readyCallback: ({ sessionUrl }) => {
            sendActionEvent({
                actionId: "FullstoryInitialized",
                actionType: "ResourceAdded",
                attributes: {
                    sessionUrl,
                },
            });
        },
    });

    initDynatrace({
        isEnabled: env.isDynatraceEnabled,
        appMonitoringUrl: env.dynatraceUrl,
    });

    initAppcues({
        isEnabled: env.isAppcuesEnabled,
        url: env.appcuesUrl,
        appName: env.appName,
    });
}
