// TODO: Look into using mantine variants for notepad styling
/**
 * @param {String} index0 - background modal color
 * @param {String} index1 - text and icon color
 * @param {String} index2 - scrollbar color
 */
export const noteColorGroups = [
    ["var(--mantine-color-dark-5)", "var(--mantine-color-dark-3)", "var(--mantine-color-dark-4)"],
    ["var(--mantine-color-gray-6)", "var(--mantine-color-dark-6)", "var(--mantine-color-gray-5)"],
    ["var(--mantine-color-pink-6)", "var(--mantine-color-dark-6)", "var(--mantine-color-pink-5)"],
    ["var(--mantine-color-violet-6)", "var(--mantine-color-dark-0)", "var(--mantine-color-violet-5)"],
    ["var(--mantine-color-blue-6)", "var(--mantine-color-dark-6)", "var(--mantine-color-blue-5)"],
    ["var(--mantine-color-cyan-6)", "var(--mantine-color-dark-6)", "var(--mantine-color-cyan-5)"],
    ["var(--mantine-color-teal-6)", "var(--mantine-color-dark-6)", "var(--mantine-color-teal-5)"],
    ["var(--mantine-color-lime-6)", "var(--mantine-color-dark-6)", "var(--mantine-color-lime-5)"],
    ["var(--mantine-color-yellow-6)", "var(--mantine-color-dark-6)", "var(--mantine-color-yellow-5)"],
    ["var(--mantine-color-orange-6)", "var(--mantine-color-dark-6)", "var(--mantine-color-orange-5)"],
];
