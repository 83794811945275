import { useExpertAssistStore } from "./store";
import type { Subscriptions } from "./types";

export const getExpertId = () => {
    return useExpertAssistStore.getState().expertProfile.id;
};
export const getSubscription = (id: keyof Subscriptions) => {
    return useExpertAssistStore.getState().subscriptions[id];
};

export const getToken = () => {
    return useExpertAssistStore.getState().token ?? "";
};

export const getSessionDetails = () => {
    const {
        expertProfile: { id },
        callSid,
        sessionId,
        partner,
        lob,
    } = useExpertAssistStore.getState();
    return { expertId: id, sessionId, callSid, partner, lob };
};
