export function ToolboxMenuCloseIcon() {
    return (
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="48" height="48" rx="24" fill="var(--mantine-color-asurion-5)" />
            <g clipPath="url(#clip0_3803_62556)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.7072 16.793L31.7072 30.793L30.293 32.2072L16.293 18.2072L17.7072 16.793Z"
                    fill="var(--mantine-color-gray-1)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.7072 18.2072L17.7072 32.2072L16.293 30.793L30.293 16.793L31.7072 18.2072Z"
                    fill="var(--mantine-color-gray-1)"
                />
            </g>
            <defs>
                <clipPath id="clip0_3803_62556">
                    <rect width="24" height="24" fill="white" transform="translate(12 12.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
